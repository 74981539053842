/* eslint-disable max-lines */
import { gql } from '@apollo/client';
import INVESTIGATION_FRAGMENTS from '../fragment/Investigation';

export const INVESTIGATION_MUTATIONS = {
  EDIT: {
    editInvestigationIsTemplate: gql`
      mutation EditInvestigationIsTemplate($data: EditInvestigationIsTemplate!) {
        editInvestigationIsTemplate(data: $data)
      }
    `,
    editInvestigationDuration: gql`
      mutation EditInvestigationDuration($data: EditInvestigationDurationInput!) {
        editInvestigationDuration(data: $data) {
          id
          investigationId
          startDate
          dueDate
          classId
        }
      }
    `,
    editAssessmentPerStudent: gql`
      mutation EditAssessmentPerStudent($data: EditAssessmentPerStudentInput!) {
        editAssessmentPerStudent(data: $data)
      }
    `,
    editAssessmentLockPerStudent: gql`
      mutation EditAssessmentLockPerStudent($data: EditAssessmentLockPerStudentInput!) {
        editAssessmentLockPerStudent(data: $data)
      }
    `,
    bulkEditAssessmentPerStudent: gql`
      mutation BulkEditAssessmentPerStudent($data: BulkEditAssessmentPerStudentInput!) {
        bulkEditAssessmentPerStudent(data: $data)
      }
    `,
  },
  PROGRESS: {
    activityCompleted: gql`
      mutation activityCompleted($stepId: String!, $activityId: String!, $completed: Boolean) {
        activityCompleted(stepId: $stepId, activityId: $activityId, completed: $completed) {
          id
          completion
          steps {
            id
            completed
          }
        }
      }
    `,
    completeActivityForClass: gql`
      mutation completeActivityForClass($stepId: String!, $activityId: String!, $completed: Boolean) {
        completeActivityForClass(stepId: $stepId, activityId: $activityId, completed: $completed) {
          id
        }
      }
    `,
    submitPeerReviewAnswer: gql`
      mutation submitPeerReviewAnswer(
        $peerReviewId: String
        $questionIndex: Int
        $answerIndex: Int
        $answer: Int
        $userId: String
      ) {
        submitPeerReviewAnswer(
          peerReviewId: $peerReviewId
          questionIndex: $questionIndex
          answerIndex: $answerIndex
          answer: $answer
          userId: $userId
        ) {
          id
          questionnaire {
            id
            feedback
            questionIndex
            answers {
              id
              answerIndex
              answer
            }
          }
        }
      }
    `,
    submitPeerReviewQuestionFeedback: gql`
      mutation submitPeerReviewQuestionFeedback(
        $peerReviewId: String
        $questionIndex: Int
        $feedback: String
        $userId: String
      ) {
        submitPeerReviewQuestionFeedback(
          peerReviewId: $peerReviewId
          questionIndex: $questionIndex
          feedback: $feedback
          userId: $userId
        ) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    submitReflectionQuestionnaireAnswer: gql`
      mutation submitReflectionAnswer($peerReviewId: String, $questionIndex: Int, $answer: String, $userId: String) {
        submitReflectionAnswer(
          peerReviewId: $peerReviewId
          questionIndex: $questionIndex
          answer: $answer
          userId: $userId
        ) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    changeVisibilityOfStudentSubmission: gql`
      mutation changeVisibilityOfStudentSubmission(
        $stepId: String!
        $studentId: String!
        $activityId: String!
        $hidden: Boolean!
      ) {
        changeVisibilityOfStudentSubmission(
          stepId: $stepId
          studentId: $studentId
          activityId: $activityId
          hidden: $hidden
        )
      }
    `,
    removeStudentFinalSubmission: gql`
      mutation removeStudentFinalSubmission(
        $investigationId: String!
        $stepId: String!
        $studentId: String!
        $activityId: String!
      ) {
        removeStudentFinalSubmission(
          investigationId: $investigationId
          stepId: $stepId
          studentId: $studentId
          activityId: $activityId
        ) {
          steps {
            id
            name
            completed
            startDate
            dueDate
            mode
            modesAvailable
            requiresPreviousStep
            activities {
              id
              stepId
              name
              completed
              inPersonTeacher
              remoteTeacher
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...TeacherContentFragment
              }
              contentInPerson {
                ...TeacherContentFragment
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.teacherContentFragment}
    `,
    submitActivityMaterials: gql`
      mutation submitActivityMaterials($activityId: String, $stepId: String, $materials: [ActivityMaterials!]) {
        submitActivityMaterials(activityId: $activityId, stepId: $stepId, materials: $materials) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
            nextGenerationStandards {
              id
              code
              grade
              state
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    submitCanvasImage: gql`
      mutation submitCanvasImage($file: Upload!, $stepId: String!, $activityId: String!, $submissionIndex: Int) {
        submitCanvasImage(file: $file, stepId: $stepId, activityId: $activityId, submissionIndex: $submissionIndex) {
          id
          activities {
            id
            stepId
            completed
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    evaluateConcept: gql`
      mutation evaluateConcept(
        $activityId: String!
        $stepId: String!
        $studentId: String!
        $answerValue: String!
        $questionIndex: Int!
        $conceptActivityId: String!
        $evaluatorId: String
      ) {
        evaluateConcept(
          activityId: $activityId
          stepId: $stepId
          studentId: $studentId
          answerValue: $answerValue
          questionIndex: $questionIndex
          conceptActivityId: $conceptActivityId
          evaluatorId: $evaluatorId
        ) {
          ...EvaluationAnswerFragment
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONCEPT.evaluationAnswerFragment}
    `,
    completeConceptEvaluation: gql`
      mutation completeConceptEvaluation($id: String!, $feedback: String, $evaluatorId: String) {
        completeConceptEvaluation(id: $id, feedback: $feedback, evaluatorId: $evaluatorId) {
          ...EvaluationAnswerFragment
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONCEPT.evaluationAnswerFragment}
    `,
  },
  COMMENTS: {
    submitComment: gql`
      mutation submitCommentByActivityId($comment: CommentInput!) {
        submitCommentByActivityId(comment: $comment) {
          ...CommentFragment
          responses {
            ...CommentFragment
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    likeComment: gql`
      mutation likeComment($commentId: String!) {
        likeComment(commentId: $commentId) {
          ...CommentFragment
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    flagComment: gql`
      mutation flagComment($commentId: String!) {
        flagComment(commentId: $commentId) {
          ...CommentFragment
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    submitPeerReview: gql`
      mutation submitPeerReview($stepId: String, $studentId: String, $comment: CommentInput!, $userId: String) {
        submitPeerReview(stepId: $stepId, studentId: $studentId, comment: $comment, userId: $userId) {
          id
          stepId
          studentId
          userId
          fileUrl
          fileMIMEtype
          createdAt
          completedAt
          comments {
            ...CommentFragment
            coordinates {
              ts
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    submitBestConceptPeerReview: gql`
      mutation submitBestConceptPeerReview(
        $conceptActivityId: String!
        $conceptClassStepId: String!
        $conceptOwnerId: String!
        $comment: CommentInput!
        $reviewerId: String
      ) {
        submitBestConceptPeerReview(
          conceptActivityId: $conceptActivityId
          conceptClassStepId: $conceptClassStepId
          conceptOwnerId: $conceptOwnerId
          comment: $comment
          reviewerId: $reviewerId
        ) {
          id
          createdAt
          completedAt
          reflectionCompletedAt
          rating
          reflection
          comments {
            ...CommentFragment
            coordinates {
              ts
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    submitReflection: gql`
      mutation submitReflection($peerReviewId: String!, $text: String!, $rating: Float, $studentId: String) {
        submitReflection(peerReviewId: $peerReviewId, text: $text, rating: $rating, studentId: $studentId) {
          id
          rating
          reflection
          completedAt
          reflectionCompletedAt
        }
      }
    `,
    submitBestConceptReflection: gql`
      mutation submitBestConceptReflection(
        $peerReviewId: String!
        $text: String!
        $rating: Float
        $revieweeId: String
      ) {
        submitBestConceptReflection(
          peerReviewId: $peerReviewId
          text: $text
          rating: $rating
          revieweeId: $revieweeId
        ) {
          id
          rating
          reflection
          completedAt
          reflectionCompletedAt
        }
      }
    `,
    deleteComment: gql`
      mutation deleteComment($commentId: String) {
        deleteComment(commentId: $commentId) {
          deleted
          commentId
        }
      }
    `,
  },
  PLAN: {
    submitFile: gql`
      mutation submitInvestigationPlanFile($file: Upload!, $stepId: String!, $activityId: String!) {
        submitInvestigationPlanFile(file: $file, stepId: $stepId, activityId: $activityId) {
          id
          activities {
            id
            stepId
            completed
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
            nextGenerationStandards {
              id
              code
              grade
              state
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    submitFinalReportFile: gql`
      mutation submitFinalReportFile($investigationid: String!, $file: Upload!) {
        submitFinalReportFile(investigationid: $investigationid, file: $file) {
          id
          steps {
            id
            activities {
              id
              stepId
              completed
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
              nextGenerationStandards {
                id
                code
                grade
                state
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    submitAssessmentFinal: gql`
      mutation SubmitAssessmentFinal($classInvestigationId: String!) {
        submitAssessmentFinal(classInvestigationId: $classInvestigationId) {
          id
          steps {
            id
            activities {
              id
              stepId
              completed
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
  },
  SETTINGS: {
    assignStepDueDate: gql`
      mutation AssignStepDueDate($classId: String, $stepId: String!, $startDate: Float!, $dueDate: Float!) {
        assignStepDueDate(classId: $classId, stepId: $stepId, startDate: $startDate, dueDate: $dueDate) {
          id
          startDate
          dueDate
        }
      }
    `,
    assignStepMode: gql`
      mutation AssignStepMode($classId: String, $stepId: String!, $mode: Mode!) {
        assignStepMode(classId: $classId, stepId: $stepId, mode: $mode) {
          id
          mode
        }
      }
    `,
    assignStepModeToAllSteps: gql`
      mutation AssignStepModeToAllSteps($investigationId: String!, $classId: String!, $mode: Mode!) {
        assignStepModesToAllSteps(investigationId: $investigationId, classId: $classId, mode: $mode)
      }
    `,
    assignPreRequisite: gql`
      mutation AssignPreRequisite($classId: String, $stepId: String!, $requiresPreviousStep: Boolean!) {
        assignPreRequisite(classId: $classId, stepId: $stepId, requiresPreviousStep: $requiresPreviousStep) {
          id
          requiresPreviousStep
        }
      }
    `,
    uploadActivityHelpVideoFile: gql`
      mutation uploadActivityHelpVideoFile(
        $helpVideoFile: Upload
        $helpVideoUrl: String
        $activityId: String!
        $teacher: Boolean!
      ) {
        uploadActivityHelpVideoFile(
          helpVideoFile: $helpVideoFile
          helpVideoUrl: $helpVideoUrl
          activityId: $activityId
          teacher: $teacher
        ) {
          id
          helpVideoUrl
          teacherHelpVideoUrl
        }
      }
    `,
    getAllInvestigationMaterials: gql`
      mutation getAllInvestigationMaterials($id: String!) {
        getAllInvestigationMaterials(id: $id) {
          id
          fileUrl
          status
        }
      }
    `,
  },
  DELETE: {
    removeInvestigationFromClasses: gql`
      mutation RemoveInvestigationFromClasses($id: String!) {
        removeInvestigationFromClasses(id: $id)
      }
    `,
  },
  DRAFT: {
    createInvestigationDraft: gql`
      mutation createInvestigationDraft($investigationDraft: InvestigationDraftEdit) {
        createInvestigationDraft(investigationDraft: $investigationDraft) {
          id
          title
          isDraft
          description
          discipline {
            ...DisciplineFragment
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          steps {
            id
            name
            activities {
              id
              stepId
              name
              content {
                ...ContentFragment
              }
              nextGenerationStandards {
                id
                code
                grade
                state
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    generateDuplicationNewVersion: gql`
      mutation generateDuplicationNewVersion {
        generateDuplicationNewVersion
      }
    `,
    cloneInvestigation: gql`
      mutation cloneInvestigation($cloneinvestigationDraft: CloneInvestigationDraftEdit) {
        cloneInvestigation(cloneinvestigationDraft: $cloneinvestigationDraft) {
          id
          title
          isDraft
          description
          discipline {
            ...DisciplineFragment
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          steps {
            id
            name
            activities {
              id
              stepId
              name
              content {
                ...ContentFragment
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    editInvestigationDraft: gql`
      mutation editInvestigationDraft(
        $investigationDraft: InvestigationDraftEdit!
        $imageFile: Upload
        $standardsFile: Upload
        $suppliesFile: Upload
        $letterHomeFile: Upload
        $teacherNotesPdf:Upload
      ) {
        editInvestigationDraft(
          investigationDraft: $investigationDraft
          imageFile: $imageFile
          standardsFile: $standardsFile
          suppliesFile: $suppliesFile
          letterHomeFile: $letterHomeFile
          teacherNotesPdf: $teacherNotesPdf
        ) {
          id
          title
          description
          explanation
          isDraft
          imageUrl
          standardsUrl
          suppliesUrl
          markedForDeletion
          suppliesUrl
          letterHomeUrl
          firstPublishedAt
          availableForTrial
          type
          kitUrl
          teacherFavorite
          isAssessment
          discipline {
            ...DisciplineFragment
          }
          coreIdeas {
            code
            text
          }
          workshopElements {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          standards {
            id
            code
            grade
          }
          nextGenerationStandards {
            id
            code
            grade
            state
          }
          steps {
            id
            name
            previewText
            inPersonPreviewText
            remotePreviewText
            order
            mode
            materials {
              ...MaterialFragment
            }
            activities {
              id
              name
              order
              inPersonTeacher
              inPersonTeacherTips
              remoteTeacher
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
              nextGenerationStandards {
                id
                code
                grade
                state
              }
              texasStandards {
                id
                code
                grade
                state
              }
            }
          }
          format {
            id
            name
          }
          focus {
            id
            name
          }
          time
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    editStepDraft: gql`
      mutation editStepDraft($stepDraft: InvestigationDraftStep!, $investigationDraftId: String!) {
        editStepDraft(stepDraft: $stepDraft, investigationDraftId: $investigationDraftId) {
          id
          name
          mode
          materials {
            ...MaterialFragment
          }
          activities {
            id
            name
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
    `,
    editActivityDraft: gql`
      mutation editActivityDraft(
        $activityDraft: InvestigationDraftActivity!
        $investigationDraftId: String!
        $stepDraftId: String
      ) {
        editActivityDraft(
          activityDraft: $activityDraft
          investigationDraftId: $investigationDraftId
          stepDraftId: $stepDraftId
        ) {
          id
          name
          order
          stepId
          inPersonTeacher
          inPersonTeacherTips
          remoteTeacher
          safety
          help
          helpVideoUrl
          teacherHelpVideoUrl
          content {
            ...ContentFragment
          }
          contentInPerson {
            ...ContentFragment
          }
          nextGenerationStandards {
            id
            code
            grade
            state
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    publishInvestigation: gql`
      mutation publishInvestigation($investigationId: String!, $publish: Boolean!) {
        publishInvestigation(investigationId: $investigationId, publish: $publish) {
          id
          isDraft
          markedForDeletion
          firstPublishedAt
        }
      }
    `,
    changeInvestigationTrialAvailability: gql`
      mutation changeInvestigationTrialAvailability($investigationId: String!, $availableForTrial: Boolean!) {
        changeInvestigationTrialAvailability(investigationId: $investigationId, availableForTrial: $availableForTrial) {
          id
          availableForTrial
        }
      }
    `,
    deleteInvestigationDraft: gql`
      mutation deleteInvestigationDraft($investigationId: String!) {
        deleteInvestigationDraft(investigationId: $investigationId)
      }
    `,
    deleteActivityDraft: gql`
      mutation deleteActivityDraft($activityDraftId: String!, $investigationDraftId: String!) {
        deleteActivityDraft(activityDraftId: $activityDraftId, investigationDraftId: $investigationDraftId) {
          id
          activities {
            id
            name
            order
            content {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    uploadStepMaterialContent: gql`
      mutation uploadStepMaterialContent($investigationDraftId: String!, $stepDraftId: String, $files: [Upload]) {
        uploadStepMaterialContent(
          investigationDraftId: $investigationDraftId
          stepDraftId: $stepDraftId
          files: $files
        ) {
          id
          materials {
            ...MaterialFragment
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
    `,
    deleteStepMaterialContent: gql`
      mutation deleteStepMaterialContent($materialId: String!) {
        deleteStepMaterialContent(materialId: $materialId) {
          id
          materials {
            ...MaterialFragment
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
    `,
    uploadActivityFile: gql`
      mutation uploadActivityFile($indexPosition: Int, $activityFile: Upload) {
        UploadActivityFile(indexPosition: $indexPosition, activityFile: $activityFile) {
          url
          index
        }
      }
    `,
  },
  TEXT_SUBMISSION: {
    submitTextSubmission: gql`
      mutation submitTextSubmission($activityId: String, $stepId: String, $text: String) {
        submitTextSubmission(activityId: $activityId, stepId: $stepId, text: $text) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    unlockTextSubmission: gql`
      mutation unlockTextSubmission($activityId: String, $stepId: String, $studentId: String) {
        unlockTextSubmission(activityId: $activityId, stepId: $stepId, studentId: $studentId) {
          id
        }
      }
    `,
  },
  PROCESS_BOARD: {
    submitProcessBoard: gql`
      mutation submitJsonSubmission($activityId: String, $stepId: String, $json: JSONSubmissionInput) {
        submitJsonSubmission(activityId: $activityId, stepId: $stepId, json: $json) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
  },
  CATEGORY_BOARD: {
    submitCategoryBoard: gql`
      mutation submitJsonSubmission($activityId: String, $stepId: String, $json: JSONSubmissionInput) {
        submitJsonSubmission(activityId: $activityId, stepId: $stepId, json: $json) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
  },
  MULTIPLE_CHOICE: {
    submitMultipleChoicePicture: gql`
      mutation submitMultipleChoicePicture($activityId: String, $stepId: String, $text: String) {
        submitMultipleChoice(activityId: $activityId, stepId: $stepId, text: $text) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    submitMultipleChoice: gql`
      mutation submitMultipleChoice($activityId: String, $stepId: String, $text: String) {
        submitMultipleChoice(activityId: $activityId, stepId: $stepId, text: $text) {
          id
          activities {
            id
            content {
              ...ContentFragment
            }
            contentInPerson {
              ...ContentFragment
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
  },
  GRADING: {
    getFinalReportGrading: gql`
      mutation GetFinalReportGrading($investigationId: String!, $userId: String) {
        getFinalReportGrading(investigationId: $investigationId, userId: $userId) {
          grade
          scoreType
          questionnaire {
            index
            title
            questions {
              index
              question
              answer
            }
            feedback
          }
        }
      }
    `,
    gradeFinalReport: gql`
      mutation GradeFinalReport(
        $investigationId: String
        $userId: String
        $gradingData: [FinalReportQuestionnaireInput]!
      ) {
        gradeFinalReport(investigationId: $investigationId, userId: $userId, gradingData: $gradingData)
      }
    `,
    gradeAssessment: gql`
      mutation GradeAssessment(
        $classAssessmentId: String
        $userId: String
        $scoreType: ScoreType
        $gradingData: [FinalReportQuestionnaireInput]!
      ) {
        gradeAssessment(
          classAssessmentId: $classAssessmentId
          userId: $userId
          scoreType: $scoreType
          gradingData: $gradingData
        )
      }
    `,
    gradeEngagement: gql`
      mutation GradeEngagement(
        $investigationId: String
        $userId: String
        $engagementGradingData: [EngagementQuestionInput]!
      ) {
        gradeEngagement(
          investigationId: $investigationId
          userId: $userId
          engagementGradingData: $engagementGradingData
        )
      }
    `,
    downloadInvestigationSubmissions: gql`
      mutation downloadInvestigationSubmissions($classInvestigationId: String, $classId: String) {
        downloadInvestigationSubmissions(classInvestigationId: $classInvestigationId, classId: $classId) {
          id
          fileUrl
          status
        }
      }
    `,
  },
  REASSIGNMENT: {
    assignSubmissionForPeerReview: gql`
      mutation assignSubmissionForPeerReview(
        $classInvestigationId: String!
        $classStepId: String!
        $activityId: String!
        $studentReceiverId: String!
        $studentSubmitterId: String!
        $notificationId: String!
        $activityType: StuckUserActivityType!
        $extraEngagementPoints: Int
      ) {
        assignSubmissionForPeerReview(
          classInvestigationId: $classInvestigationId
          classStepId: $classStepId
          activityId: $activityId
          studentReceiverId: $studentReceiverId
          studentSubmitterId: $studentSubmitterId
          notificationId: $notificationId
          activityType: $activityType
          extraEngagementPoints: $extraEngagementPoints
        )
      }
    `,
    notifyTeacherAboutStuckUser: gql`
      mutation notifyTeacherAboutStuckUser(
        $classId: String!
        $activityId: String!
        $studentId: String!
        $activityType: StuckUserActivityType!
      ) {
        notifyTeacherAboutStuckUser(
          classId: $classId
          activityId: $activityId
          studentId: $studentId
          activityType: $activityType
        )
      }
    `,
    notifyStudentReassignmentDoNothing: gql`
      mutation notifyStudentReassignmentDoNothing(
        $classId: String!
        $activityId: String!
        $studentId: String!
        $activityType: StuckUserActivityType!
        $notificationId: String!
      ) {
        notifyStudentReassignmentDoNothing(
          classId: $classId
          activityId: $activityId
          studentId: $studentId
          activityType: $activityType
          notificationId: $notificationId
        )
      }
    `,
    createTeacherPeerReview: gql`
      mutation createTeacherPeerReview($studentId: String!, $classStepId: String!, $activityId: String!) {
        createTeacherPeerReview(studentId: $studentId, classStepId: $classStepId, activityId: $activityId)
      }
    `,
  },
};

export default INVESTIGATION_MUTATIONS;
