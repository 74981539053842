/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import SettingsCollapsable from '../../../shared/SettingsCollapsable';
import Button from '../../../shared/Button';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import { FiArrowRight, FiTrash2, FiUpload } from 'react-icons/fi';
import { SelectValue } from 'antd/lib/select';
import { message, Popconfirm, Spin, Switch } from 'antd';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import {
  GQL_CodeTextPair,
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationMetadataCodebook,
} from '../../../types/investigation';
import { clearKeyFromObject } from '../../../utils/investigation';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_InvestigationStandard } from '../../../types/investigationStandard';
import { cleanCoreIdeaCode, sortGradeList, sortTexasStandardsByCode } from '../../../utils/utils';
import AssessmentFocus from './Focus';
import { INVESTIGATION_STANDARDS } from '../../../types/standards';
import { SUBJECT_TYPES } from '../../../types/subjects';
import { sortBy } from 'lodash';
import { RcFile } from 'antd/lib/upload';

interface ICoreSettingsSider {
  investigationId: string;
}

const TEXAS_EDITION_LABEL = 'Science:_Texas_Edition_(2024_Adoption)';

// eslint-disable-next-line max-statements,complexity,max-lines
const CoreSettingsSider = (props: ICoreSettingsSider) => {
  const { investigationId } = props;
  const history = useHistory();
  const { isAdiSuperAdmin, user, isOrganizationAdiAdmin } = useAuth();
  const [investigationType, setInvestigationType] = useState('Math');
  const [disciplineId, setDisciplineId] = useState<string>();
  const [formatId, setFormatId] = useState<string>();
  const [focusId, setFocusId] = useState<string>();
  const [selectedGradeBand, setSelectedGradeBand] = useState<string>();
  const [hasEditedData, setHasEditedData] = useState(false);
  const [investigationDraft, setInvestigationDraft] = useState<GQL_InvestigationCatalogEntry>();
  const [disableTexasStandardsField, setDisableTexasStandardsField] = useState(false);
  const [disabledNGStandardsField, setDisabledNGStandardsField] = useState(false);
  const [texasStandardListField, setTexasStandardList] = useState<GQL_InvestigationStandard[] | undefined>();
  const [ngssStandardListField, setNgssStandardList] = useState<GQL_InvestigationStandard[] | undefined>();
  const [teacherNotesPDF, setTeacherNotesPDF] = useState<RcFile>();

  const handleGoBack = () => history.goBack();

  const beforeUploadTeacherNotesPDF = (file: RcFile) => {
    if (!file.type.includes('pdf')) return false;
    setTeacherNotesPDF(file);
    return true;
  };

  const handleClearTeacherNotesPDF = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();

    setTeacherNotesPDF(undefined);
  };

  const [fetchWorkshopElements, { data: workshopElementsData, loading: loadingWorkshopElements }] = useLazyQuery<{
    getWorkshopElements: GQL_CodeTextPair[];
  }>(gqlSchema.InvestigationSchema.queries.CREATION.getWorkshopElements, {
    onError: (err) => {
      message.error('There was an error loading codes: ' + err.message || 'Unexpected Error');
    },
  });

  useQuery<{ getInvestigationDraftById: GQL_InvestigationCatalogEntry }, { id: string }>(
    gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById,
    {
      variables: {
        id: investigationId,
      },
      onCompleted: ({ getInvestigationDraftById }) => {
        setInvestigationDraft(clearKeyFromObject(getInvestigationDraftById));
        setInvestigationType(getInvestigationDraftById.discipline?.subject || 'Math');
        setSelectedGradeBand(getInvestigationDraftById.discipline?.gradeBand);
        setDisciplineId(getInvestigationDraftById.discipline?.id);
        setFormatId(getInvestigationDraftById.format?.id);
        setFocusId(getInvestigationDraftById.focus?.id);
        if (getInvestigationDraftById.type === 'WORKSHOP') {
          fetchWorkshopElements();
        }
      },
      onError: (err) => {
        message.error(
          `There was an error getting the ${(investigationTypeTitle !== undefined) ? investigationTypeTitle.toLowerCase() : "investigation/assessment"}: ` +
            (err.message || 'Unexpected Error'),
        );
        handleGoBack();
      },
    },
  );

  const { data: stateStandardsData, loading: loadingStateStandards } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.TEXAS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: ngssStandardsData, loading: loadingNGSSStateStandards } = useQuery<{
    getStateStandards: GQL_InvestigationStandard[];
  }>(gqlSchema.InvestigationStandardSchema.queries.GET.getStateStandards, {
    variables: {
      state: INVESTIGATION_STANDARDS.NGSS,
    },
    onError: (error) => {
      message.error(`There was an error in fetching the texas standards - ${error.message || 'Unexpected Error'}`);
    },
  });

  const [deleteInvestigationDraft, { loading: loadingDeletion }] = useMutation<
    { deleteInvestigationDraft: boolean },
    { investigationId: string }
  >(gqlSchema.InvestigationSchema.mutations.DRAFT.deleteInvestigationDraft, {
    onError: (err) => message.error(err.message),
    onCompleted: () => {
      handleGoBack();
      message.success(`${investigationTypeTitle} deleted successfully`);
    },
    refetchQueries: ['getInvestigationDrafts', 'getInvestigationCatalog'],
    awaitRefetchQueries: true,
  });

  const [updatePublishStatus, { loading: loadingPublish }] = useMutation<{
    publishInvestigation: { isDraft: boolean; markedForDeletion: number };
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.publishInvestigation, {
    onError: (err) => message.error(err.message || 'Unexpected Error'),
    onCompleted: ({ publishInvestigation }) => {
      if (investigationDraft) {
        setInvestigationDraft({
          ...investigationDraft,
          isDraft: publishInvestigation.isDraft,
          markedForDeletion: publishInvestigation.markedForDeletion,
        });
      }
    },
  });

  const [updateTrialStatus, { loading: loadingTrialChange }] = useMutation<{
    changeInvestigationTrialAvailability: { availableForTrial: boolean };
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.changeInvestigationTrialAvailability, {
    onError: (err) =>
      message.error(
        `There was an error updating your ${(investigationTypeTitle !== undefined) ? investigationTypeTitle.toLowerCase() : "investigation/assessment"}: ` +
          (err.message || 'Unexpected Error'),
      ),
    onCompleted: ({ changeInvestigationTrialAvailability }) => {
      if (investigationDraft) {
        setInvestigationDraft({
          ...investigationDraft,
          availableForTrial: !!changeInvestigationTrialAvailability?.availableForTrial,
        });
      }
    },
  });

  const [editInvestigation, { loading: loadingSaveInvestigation }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.DRAFT.editInvestigationDraft,
    {
      onCompleted: () => {
        message.success(`${investigationTypeTitle} saved successfully`);
      },
      onError: (err) => {
        message.error(
          `There was an error saving the ${(investigationTypeTitle !== undefined) ? investigationTypeTitle.toLowerCase() : "investigation/assessment"}: ` +
            (err.message || 'Unexpected Error'),
        );
      },
      refetchQueries: ['getInvestigationCatalog', 'getInvestigationDrafts'],
    },
  );

  const { data: codebookData, loading: loadingCodebook } = useQuery<{
    getInvestigationMetadataCodebook: GQL_InvestigationMetadataCodebook;
  }>(gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationMetadataCodebook, {
    onError: (err) => {
      message.error('There was an error loading codes: ' + err.message || 'Unexpected Error');
    },
  });

  const codebook = codebookData?.getInvestigationMetadataCodebook;

  const disciplines = codebook?.disciplines?.filter(
    (discipline) => discipline.gradeBand === selectedGradeBand && discipline.subject === investigationType,
  );
  const gradeBands = Array.from(
    new Set(
      codebook?.disciplines
        ?.filter((d) => d.subject === investigationType)
        ?.map((discipline) => discipline.gradeBand) || [],
    ),
  ).sort();

  const coreIdeas =
    (investigationType === 'Engineering'
      ? codebook?.disciplines
          ?.filter((d) => d.subject === 'Engineering' || d.gradeBand === selectedGradeBand)
          ?.map((d) => d.coreIdeas)
          ?.flatMap((v) => (v ? v : []))
      : codebook?.disciplines.find((d) => d.id === disciplineId)?.coreIdeas) || [];

  const crosscuttingConcepts = codebook?.crosscuttingConcepts;
  const practices = codebook?.practices;

  const handleSelectInvestigation = (value: SelectValue) => {
    setHasEditedData(true);
    setInvestigationType(value as string);
    handleSelectGradeBand(undefined);
  };

  const handleSelectDiscipline = (value?: SelectValue) => {
    setHasEditedData(true);
    setDisciplineId(value as string);
    handleCoreIdeasChange([]);
    handleInvestigationDraftFieldUpdate('nextGenerationStandards', [] as GQL_InvestigationStandard[]);

    handleInvestigationDraftFieldUpdate('coreIdeas', []);
    handleInvestigationDraftFieldUpdate('crosscuttingConcepts', []);
    handleInvestigationDraftFieldUpdate('workshopElements', []);

    if (investigationDraft) {
      setInvestigationDraft({
        ...investigationDraft,
        coreIdeas: [],
        crosscuttingConcepts: [],
        workshopElements: [],
        practices: [],
      });
    }
  };

  const handleFormatChange = (value?: SelectValue) => {
    setHasEditedData(true);
    setFormatId(value as string);
  };

  const handleFocusChange = (value?: SelectValue) => {
    setHasEditedData(true);
    setFocusId(value as string);
  };

  const currentDiscipline = useMemo(() => {
    return codebook?.disciplines?.find((d) => d.id === disciplineId);
  }, [codebook, disciplineId]);

  const handleSelectGradeBand = (value?: SelectValue) => {
    const currentValue = value as string;
    setHasEditedData(true);
    setSelectedGradeBand(currentValue);
    handleFilterStateStandardsByGrade(currentValue);
    setDisableTexasStandardsField(value === undefined);
    handleInvestigationDraftFieldUpdate('nextGenerationStandards', [] as GQL_InvestigationStandard[]);

    handleInvestigationDraftFieldUpdate('coreIdeas', []);
    handleInvestigationDraftFieldUpdate('crosscuttingConcepts', []);
    handleInvestigationDraftFieldUpdate('workshopElements', []);

    if (investigationDraft) {
      setInvestigationDraft({
        ...investigationDraft,
        coreIdeas: [],
        crosscuttingConcepts: [],
        workshopElements: [],
        practices: [],
      });
    }

    // update discipline to match the new selected grade band
    const currentDiscipline = codebook?.disciplines?.find((d) => d.id === disciplineId);
    if (!!currentDiscipline && currentDiscipline?.gradeBand !== currentValue) {
      const relatedDiscipline = codebook?.disciplines?.find(
        (d) => d.name === currentDiscipline.name && d.gradeBand === currentValue,
      );
      handleSelectDiscipline(relatedDiscipline ? relatedDiscipline.id : undefined);
    }
  };

  const handleFilterStateStandardsByGrade = (selectedGradeBand: string) => {
    const filteredStateStandards =
      investigationDraft?.standards
        ?.filter((standard) =>
          compareSelectedGradeBandToStandardGrade(
            standard,
            selectedGradeBand,
            investigationType,
            currentDiscipline?.name,
          ),
        )
        .map((standard) => standard.code) || [];

    const filteredNGStandards =
      investigationDraft?.standards
        ?.filter((standard) =>
          compareSelectedGradeBandToNGStandardGradeAndDiscipline(standard, selectedGradeBand, disciplineId),
        )
        .map((standard) => standard.code) || [];

    if (filteredStateStandards.length !== investigationDraft?.standards?.length) {
      handleStandardsChange(filteredStateStandards, INVESTIGATION_STANDARDS.TEXAS);
    }

    if (filteredNGStandards.length !== investigationDraft?.standards?.length) {
      handleStandardsChange(filteredStateStandards, INVESTIGATION_STANDARDS.NGSS);
    }
  };

  const handleSaveThenNavigate = (url: string, state?: unknown) => {
    handleSaveSettings().then(() => {
      history.push(url, state);
    });
  };

  const handleDeleteInvestigation = () => {
    deleteInvestigationDraft({
      variables: {
        investigationId,
      },
    });
  };

  const validateSettingsData = () => {
    // don't allow the user to save if they have selected a grade band but did not select a discipline
    // reason: we do not save the gradeband on investigations, only infer them from the discipline
    if (selectedGradeBand !== undefined && disciplineId === undefined) {
      message.error('Please select a discipline before proceeding.');
      return false;
    }

    return true;
  };

  const handleSaveSettings = async () => {
    // {console.log("1",investigationDraft,"2",hasEditedData,"3",validateSettingsData(),"4",teacherNotesPDF)}
    if (investigationDraft && hasEditedData && validateSettingsData()) {
      await editInvestigation({
        variables: {
          investigationDraft: {
            ...investigationDraft,
            discipline: { id: disciplineId },
            format: { id: formatId },
            focus: { id: focusId },
            isDraft: undefined,
            steps: undefined,
            imageUrl: undefined,
            standardsUrl: undefined,
            markedForDeletion: undefined,
            firstPublishedAt: undefined,
            suppliesUrl: undefined,
            letterHomeUrl: undefined,
            availableForTrial: undefined,
          },
          teacherNotesPdf:teacherNotesPDF,
        },
      });
      setHasEditedData(false);
    }
  };

  const handleInvestigationDraftFieldUpdate = useCallback(
    (fieldName: string, value: any) => {
      if (investigationDraft) {
        if (!hasEditedData) setHasEditedData(true);
        setInvestigationDraft({ ...investigationDraft, [fieldName]: value });
      }else
      if(teacherNotesPDF){
        setHasEditedData(true);
      }
    },
    [hasEditedData, investigationDraft],
  );

  const handlePracticesChange = (v: SelectValue) => {
    const codes = (v as string[]).map((c) => ({ code: c }));
    handleInvestigationDraftFieldUpdate('practices', codes);
  };

  const handleCrosscuttingConceptsChange = (v: SelectValue) => {
    const codes = (v as string[]).map((c) => ({ code: c }));
    handleInvestigationDraftFieldUpdate('crosscuttingConcepts', codes);
  };

  const handleWorkshopElementsChange = (v: SelectValue) => {
    const codes = (v as string[]).map((c) => ({ code: c }));
    handleInvestigationDraftFieldUpdate('workshopElements', codes);
  };

  const handleCoreIdeasChange = (v: SelectValue) => {
    const codes = (v as string[]).map((c) => ({ code: c }));
    handleInvestigationDraftFieldUpdate('coreIdeas', codes);
  };

  const handleStandardsChange = useCallback(
    (v: SelectValue, standard = INVESTIGATION_STANDARDS.TEXAS) => {
      const source =
        standard === INVESTIGATION_STANDARDS.TEXAS
          ? stateStandardsData?.getStateStandards
          : standard === INVESTIGATION_STANDARDS.NGSS
          ? ngssStandardsData?.getStateStandards
          : stateStandardsData?.getStateStandards;

      const standards = (v as string[]).map((standardCode) => {
        const standard = (source || []).find((standard) => standard.code === standardCode);
        return {
          id: standard?.id,
          code: standard?.code,
          grade: standard?.grade,
        };
      });

      const key =
        standard === INVESTIGATION_STANDARDS.TEXAS
          ? 'standards'
          : standard === INVESTIGATION_STANDARDS.NGSS
          ? 'nextGenerationStandards'
          : 'standards';
      handleInvestigationDraftFieldUpdate(key, standards as GQL_InvestigationStandard[]);
    },
    [stateStandardsData, ngssStandardsData, handleInvestigationDraftFieldUpdate],
  );

  const investigationIsPublished = !investigationDraft?.isDraft && !investigationDraft?.markedForDeletion;

  const handlePublishInvestigation = async () => {
    if (!loadingPublish) {
      await updatePublishStatus({
        variables: {
          investigationId,
          publish: !investigationIsPublished,
        },
      });

      if (investigationIsPublished) {
        message.success(`${investigationTypeTitle} deleted successfully`);
      }
    }
  };

  const handleToggleInvestigationTrial = async () => {
    if (!loadingPublish) {
      await updateTrialStatus({
        variables: {
          investigationId,
          availableForTrial: !investigationDraft?.availableForTrial,
        },
      });

      if (investigationIsPublished) {
        message.success(`${investigationTypeTitle} updated successfully`);
      }
    }
  };

  const compareSelectedGradeBandToStandardGrade = (
    standard: GQL_InvestigationStandard,
    selectedGradeBand: string | undefined,
    investigationType: string | undefined,
    selectedDiscipline?: string | undefined,
  ): boolean => {
    if (selectedGradeBand === undefined) return false;
    // since grade 'K' is lower than 1, we treat it as '0' in calculations
    const selectedGrade = selectedGradeBand.replaceAll('K', '0').replaceAll('tx.', '');
    const standardGrade = standard.grade.replaceAll('K', '0').replaceAll('tx.', '');

    const parsedStandardGrade = parseInt(standardGrade);

    // if grade band is '9-12' we want to compare the grades by discipline as well
    if (
      (investigationType === SUBJECT_TYPES.SCIENCE || investigationType === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION) &&
      selectedGrade === '9-12' &&
      !!selectedDiscipline
    ) {
      return standard.grade === '9-12' && standard.discipline === selectedDiscipline;
    }

    // case where grade band is a range
    if (selectedGrade.includes('-')) {
      const [lowGrade, highGrade] = selectedGrade.split('-').map((value) => parseInt(value));
      return lowGrade <= parsedStandardGrade && parsedStandardGrade <= highGrade;
    }

    // case where grade band is a single case
    let parsedSelectedGradeBand = parseInt(selectedGradeBand);
    if(isNaN(parsedSelectedGradeBand)) parsedSelectedGradeBand = 0;
    return parsedStandardGrade === parsedSelectedGradeBand;
  };

  const compareSelectedGradeBandToNGStandardGradeAndDiscipline = useCallback(
    (
      standard: GQL_InvestigationStandard,
      selectedGradeBand: string | undefined,
      disciplineId: string | undefined,
    ): boolean => {
      if (!disciplineId && !disciplines?.length) return false;

      if (selectedGradeBand === undefined) return false;

      const selectedGrade = selectedGradeBand.replaceAll('K', '0').replaceAll('tx.', '');
      const currentDiscipline = disciplines?.find((discipline) => discipline.id === disciplineId);

      if (!standard.discipline?.toLocaleLowerCase().includes((currentDiscipline?.name || '')?.toLocaleLowerCase()))
        return false;
      // if (standard.discipline?.toLocaleLowerCase() !== currentDiscipline?.name?.toLocaleLowerCase()) return false;

      const pattern = /\b(?:Grade|School|tx.|ngss.)\b/gi;
      const sanitizeBand = standard.grade.replace(pattern, '');
      
      let parsedStandardGrade = parseInt(sanitizeBand);
      if(isNaN(parsedStandardGrade)) parsedStandardGrade = 0;

      if (selectedGrade.includes('-')) {
        const [lowGrade, highGrade] = selectedGrade.split('-').map((value) => {
          const val = parseInt(value);
          if(isNaN(val)) {
            return 0;
          } else {
            return val;
          }
        });
        // if (selectedGrade.includes('K')) {
        //   return parsedStandardGrade <= highGrade;
        // }

        return lowGrade <= parsedStandardGrade && parsedStandardGrade <= highGrade;
      }

      let parsedSelectedGradeBand = parseInt(selectedGradeBand);
      if(isNaN(parsedSelectedGradeBand)) parsedSelectedGradeBand = 0;

      return parsedStandardGrade === parsedSelectedGradeBand;
    },
    [disciplines],
  );

  const standardList = useMemo(
    () =>
      stateStandardsData?.getStateStandards.filter((standard) =>
        compareSelectedGradeBandToStandardGrade(
          standard,
          selectedGradeBand,
          investigationType,
          currentDiscipline?.name,
        ),
      ),
    [stateStandardsData, selectedGradeBand, investigationType, currentDiscipline],
  );

  const ngssStandardList = useMemo(
    () =>
      ngssStandardsData?.getStateStandards.filter((standard) =>
        compareSelectedGradeBandToNGStandardGradeAndDiscipline(standard, selectedGradeBand, disciplineId),
      ),
    [ngssStandardsData, selectedGradeBand, disciplineId, compareSelectedGradeBandToNGStandardGradeAndDiscipline],
  );

  // Trigger the Dropdown visibility
  const onDropdownVisibleChange = (isOpen: boolean) => {
    if (isOpen) {
      setTimeout(() => setTexasStandardList(standardList), 0);
      setTimeout(() => setNgssStandardList(ngssStandardList), 0);
    } else {
      setTexasStandardList([]);
      setNgssStandardList([]);
    }
  };

  const canPublish = useMemo(
    () => !loadingPublish && ((isOrganizationAdiAdmin && user.subscription?.customLibrary) || isAdiSuperAdmin),
    [isAdiSuperAdmin, isOrganizationAdiAdmin, loadingPublish, user],
  );

  useEffect(() => {
    const enabled = disciplineId !== undefined && disciplines?.length !== 0 && gradeBands?.length !== 0;

    setDisabledNGStandardsField(!enabled);
  }, [disciplineId, disciplines, gradeBands, ngssStandardListField]);

  const texasStandardsList: Record<string, GQL_InvestigationStandard[]> = useMemo(() => {
    if (!texasStandardListField) return Object.create(null);

    return texasStandardListField.reduce((previous, current) => {
      const grade = current.grade;
      previous[grade] = previous[grade] || [];
      previous[grade].push(current);
      return previous;
    }, Object.create(null));
  }, [texasStandardListField]);

  const ngssStandardsList: Record<string, GQL_InvestigationStandard[]> = useMemo(() => {
    if (!ngssStandardListField) return Object.create(null);

    return ngssStandardListField.reduce((previous, current) => {
      const grade = current.code.split('-')[0];
      previous[grade] = previous[grade] || [];
      previous[grade].push(current);
      return previous;
    }, Object.create(null));
  }, [ngssStandardListField]);

  if (!investigationDraft) return <Spin />;

  const maxCoreIdeas = investigationType === 'Engineering' ? 3 : 2;
  const investigationTypeTitle = investigationDraft.isAssessment ? 'Assessment' : 'Investigation';
  const investigationCoreSettingsTitle = investigationDraft.isAssessment ? 'Assessed' : 'Investigation';
  const settingsTitle = investigationDraft.isAssessment ? 'Assessment' : 'Stage';

  return (
    <Sider>
      <S.SiderContainer data-cy="components-adi-core-settings-sider-container">
        <S.SettingsContainer>
          <Popconfirm
            placement="bottomLeft"
            title={
              <p style={{ width: 320, marginBottom: 0 }}>
                {!investigationIsPublished
                  ? "Do you really want to publish the investigation? After publishing, you won't be able to unpublish unless you delete it."
                  : "Do you really want to unpublish this investigation? After that it will be marked to deletion and won't be able to be published again."}
              </p>
            }
            onConfirm={handlePublishInvestigation}
            disabled={!canPublish}
          >
            <S.PublishInvestigationContainer disabled={!canPublish}>
              <h2>{`Published ${investigationTypeTitle}`}</h2>
              <Switch
                loading={loadingPublish}
                disabled={!canPublish}
                size="small"
                checked={investigationIsPublished}
                data-cy="components-adi-core-settings-sider-publish-content"
              />
            </S.PublishInvestigationContainer>
          </Popconfirm>
          <Spacer size={32} />
          {!investigationDraft.isAssessment ? (
            <div>
              <Popconfirm
                placement="bottomLeft"
                title={
                  <p style={{ width: 320, marginBottom: 0 }}>
                    {investigationDraft.availableForTrial
                      ? 'Do you really want to remove this investigation from trial users? Investigation will still be available for non-trial users.'
                      : 'Do you really want to make this investigation available for trial users? Investigation will still be available for non-trial users.'}
                  </p>
                }
                onConfirm={handleToggleInvestigationTrial}
                disabled={!investigationIsPublished || !isAdiSuperAdmin}
              >
                <S.PublishInvestigationContainer disabled={!investigationIsPublished || !isAdiSuperAdmin}>
                  <h2>Available For Trial</h2>
                  <Switch
                    data-cy="components-adieditinvestigationcorepage-trial-toggle"
                    loading={loadingTrialChange}
                    disabled={!investigationIsPublished || !isAdiSuperAdmin}
                    size="small"
                    checked={investigationDraft.availableForTrial}
                  />
                </S.PublishInvestigationContainer>
              </Popconfirm>
              <Spacer size={32} />
            </div>
          ) : null}
          <SettingsCollapsable title="Core Settings">
            <S.TeacherFavoriteContainer
              onClick={() =>
                handleInvestigationDraftFieldUpdate('teacherFavorite', !investigationDraft.teacherFavorite)
              }
            >
              <h2>Teacher Favorite</h2>
              <Switch size="small" checked={investigationDraft.teacherFavorite} />
            </S.TeacherFavoriteContainer>
            <Spacer />
            <h2>{`${investigationTypeTitle} Name`}</h2>
            <Input
              placeholder="Name"
              backgroundColor="white"
              value={investigationDraft.title}
              onChange={(e) => {
                handleInvestigationDraftFieldUpdate('title', e.target.value);
              }}
            />
            <Spacer />
            <SettingsCollapsable title="Teacher Notes PDF" defaultOpen={true}>
            <h2>Upload PDF</h2>
            <S.UploadOutsider
              accept=".pdf"
              beforeUpload={beforeUploadTeacherNotesPDF}
              defaultFileList={[]}
              fileList={[]}
              customRequest={({ file }) => {
                setTeacherNotesPDF(file as RcFile);
                handleInvestigationDraftFieldUpdate('teacherNotesPdf', file);
            }}
            >
              <S.UploadContainer>
                <FiUpload />
                <Spacer axis="horizontal" />
                {console.log("teacherNotesPDF",teacherNotesPDF)}
                {teacherNotesPDF ? teacherNotesPDF.name : `${investigationDraft.teacherNotesPdf ? 'Replace' : 'Add a'} PDF`}
                {teacherNotesPDF && <FiTrash2 onClick={handleClearTeacherNotesPDF} />}
              </S.UploadContainer>
            </S.UploadOutsider>
          </SettingsCollapsable>

            <Spacer />
            {!investigationDraft.isAssessment ? (
              <>
                <h2>Investigation Kit Url</h2>
                <Input
                  placeholder="Kit Url (Optional)"
                  backgroundColor="white"
                  value={investigationDraft.kitUrl}
                  onChange={(e) => {
                    handleInvestigationDraftFieldUpdate('kitUrl', e.target.value);
                  }}
                />
                <Spacer />
              </>
            ) : (
              <>
                <h2>Assessment Description</h2>
                <Input
                  placeholder="Description"
                  backgroundColor="white"
                  data-cy="components-adieditassessmentcore-settings-description"
                  value={investigationDraft.description}
                  onChange={(e) => {
                    handleInvestigationDraftFieldUpdate('description', e.target.value);
                  }}
                />
                <Spacer />
              </>
            )}
            <h2>{`${investigationTypeTitle} Type`}</h2>
            <Select
              placeholder="Type"
              onSelect={handleSelectInvestigation}
              value={investigationType}
              data-cy="components-adieditassessmentcore-settings-type"
            >
              <SelectOption value="Science">Science</SelectOption>
              <SelectOption value="Math">Math</SelectOption>
              <SelectOption value="Engineering">Engineering</SelectOption>
              <SelectOption value={TEXAS_EDITION_LABEL}>Science: Texas Edition (2024 Adoption)</SelectOption>
            </Select>
            <Spacer />

            {investigationDraft.isAssessment && (
              <>
                <AssessmentFocus
                  title={`${investigationTypeTitle} Focus`}
                  value={focusId}
                  onSelect={handleFocusChange}
                />
                <Spacer />
              </>
            )}

            {investigationDraft.isAssessment && (
              <>
                <h2>{`${investigationTypeTitle} Time to Complete`}</h2>
                <Select
                  placeholder="Select Time"
                  optionLabelProp="label"
                  showArrow
                  value={investigationDraft.time}
                  onSelect={(value) => {
                    handleInvestigationDraftFieldUpdate('time', value);
                  }}
                >
                  <SelectOption value={5} label="5 minutes">
                    5 minutes
                  </SelectOption>
                  <SelectOption value={10} label="10 minutes">
                    10 minutes
                  </SelectOption>
                  <SelectOption value={15} label="15 minutes">
                    15 minutes
                  </SelectOption>
                  <SelectOption value={30} label="30 minutes">
                    30 minutes
                  </SelectOption>
                  <SelectOption value={45} label="45 minutes">
                    45 minutes
                  </SelectOption>
                  <SelectOption value={60} label="60 minutes">
                    60 minutes
                  </SelectOption>
                </Select>
                <Spacer />
              </>
            )}

            <h2>{`${investigationTypeTitle} Grade`}</h2>
            <Select
              placeholder="Grade"
              data-cy="components-adieditassessmentcore-settings-grade"
              value={selectedGradeBand}
              loading={loadingCodebook}
              onSelect={handleSelectGradeBand}
            >
              {gradeBands
                .sort((a, b) => {
                  return a.replaceAll('K', '0').localeCompare(b.replaceAll('K', '0'));
                })
                .map((gradeBand) => (
                  <SelectOption value={gradeBand} key={gradeBand}>
                    {investigationType === TEXAS_EDITION_LABEL ? 'Grade' : 'Grades'} {gradeBand.replace('tx.', '')}
                  </SelectOption>
                ))}
            </Select>

            <Spacer />
            <h2>{`${investigationCoreSettingsTitle} Discipline`}</h2>
            <Select
              placeholder="Discipline"
              data-cy="components-adieditassessmentcore-settings-discipline"
              onSelect={handleSelectDiscipline}
              value={disciplineId}
              disabled={!selectedGradeBand}
              loading={loadingCodebook}
            >
              {disciplines?.map((discipline) => (
                <SelectOption value={discipline.id} key={discipline.id}>
                  {discipline.name}
                </SelectOption>
              ))}
            </Select>
            <Spacer />

            <h2>{`${investigationCoreSettingsTitle} Core Ideas`}</h2>
            <Select
              placeholder="Core Ideas"
              data-cy="components-adieditassessmentcore-settings-core-ideas"
              optionLabelProp="label"
              dropdownMatchSelectWidth={false}
              disabled={!disciplineId}
              loading={loadingCodebook}
              showSearch
              filterOption={(input, option) =>
                option?.name?.toString()?.toLocaleLowerCase()?.includes(input?.toLocaleLowerCase()) ?? false
              }
              mode="multiple"
              onChange={handleCoreIdeasChange}
              value={investigationDraft.coreIdeas?.map((ci) => ci.code)}
              showArrow
            >
              {coreIdeas.map((coreIdea) => (
                <SelectOption
                  value={coreIdea.code}
                  label={cleanCoreIdeaCode(coreIdea.code)}
                  key={coreIdea.code}
                  name={`(${cleanCoreIdeaCode(coreIdea.code)}) ${coreIdea.text}`}
                  disabled={
                    investigationDraft.coreIdeas?.length === maxCoreIdeas &&
                    !investigationDraft.coreIdeas.some((ci) => ci.code === coreIdea.code)
                  }
                >
                  ({cleanCoreIdeaCode(coreIdea.code)}) {coreIdea.text}
                </SelectOption>
              ))}
            </Select>

            {investigationDraft.isAssessment && (
              <>
                <Spacer />
                <h2>{`${investigationTypeTitle} Crosscutting Concepts`}</h2>
                <Select
                  placeholder="Crosscutting Concepts"
                  optionLabelProp="label"
                  showSearch={false}
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  showArrow
                  loading={loadingCodebook}
                  value={investigationDraft.crosscuttingConcepts?.map((cc) => cc.code)}
                  onChange={handleCrosscuttingConceptsChange}
                >
                  {crosscuttingConcepts?.map((cc) => (
                    <SelectOption
                      value={cc.code}
                      label={cc.code}
                      key={cc.code}
                      disabled={
                        investigationDraft.crosscuttingConcepts?.length === 2 &&
                        !investigationDraft.crosscuttingConcepts.some((v) => v.code === cc.code)
                      }
                    >
                      ({cc.code}) {cc.text}
                    </SelectOption>
                  ))}
                </Select>
              </>
            )}

            <Spacer />
            <h2>{`${investigationCoreSettingsTitle} Practices`}</h2>
            <Select
              placeholder="Practices"
              data-cy="components-adieditassessmentcore-settings-core-practices"
              optionLabelProp="label"
              showSearch={false}
              dropdownMatchSelectWidth={false}
              mode="multiple"
              showArrow
              loading={loadingCodebook}
              value={investigationDraft.practices?.map((p) => p.code)}
              onChange={handlePracticesChange}
            >
              {practices?.map((practice) => (
                <SelectOption
                  value={practice.code}
                  label={practice.code}
                  key={practice.code}
                  disabled={
                    investigationDraft.practices?.length === 6 &&
                    !investigationDraft.practices.some((p) => p.code === practice.code)
                  }
                >
                  ({practice.code}) {practice.text}
                </SelectOption>
              ))}
            </Select>
            {!investigationDraft.isAssessment && (
              <>
                <Spacer />
                <h2>{`${investigationTypeTitle} Crosscutting Concepts`}</h2>
                <Select
                  placeholder="Crosscutting Concepts"
                  optionLabelProp="label"
                  showSearch={false}
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  showArrow
                  loading={loadingCodebook}
                  value={investigationDraft.crosscuttingConcepts?.map((cc) => cc.code)}
                  onChange={handleCrosscuttingConceptsChange}
                >
                  {crosscuttingConcepts?.map((cc) => (
                    <SelectOption
                      value={cc.code}
                      label={cc.code}
                      key={cc.code}
                      disabled={
                        investigationDraft.crosscuttingConcepts?.length === 2 &&
                        !investigationDraft.crosscuttingConcepts.some((v) => v.code === cc.code)
                      }
                    >
                      ({cc.code}) {cc.text}
                    </SelectOption>
                  ))}
                </Select>
              </>
            )}

            {!investigationDraft.isAssessment && (
              <>
                <Spacer />
                <h2>Texas Standards</h2>
                <Select
                  placeholder="Texas Standards"
                  optionLabelProp="label"
                  showSearch={false}
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  showArrow
                  loading={loadingStateStandards}
                  value={investigationDraft?.standards?.map((standard) => standard.code)?.sort()}
                  onChange={(change) => handleStandardsChange(change, INVESTIGATION_STANDARDS.TEXAS)}
                  disabled={disableTexasStandardsField}
                  onDropdownVisibleChange={onDropdownVisibleChange}
                >
                  {Object.keys(texasStandardsList)
                    .sort(sortGradeList)
                    .map((grade, index) => {
                      const parsedGrade = grade.includes('-') || grade.startsWith('K') ? grade : parseInt(grade);
                      return (
                        <>
                          <SelectOption key={index} value={grade} label={grade} disabled>
                            <strong>Grade {parsedGrade}</strong>
                          </SelectOption>
                          {texasStandardsList[grade].sort(sortTexasStandardsByCode).map((standard) => (
                            <SelectOption
                              value={standard.code}
                              label={standard.code}
                              key={standard.code}
                              style={{
                                maxWidth: '400px',
                              }}
                            >
                              {standard.code} - {standard.fullStatement}
                            </SelectOption>
                          ))}
                        </>
                      );
                    })}
                </Select>
                <Spacer />
                <h2>NGSS Standards</h2>
                <Select
                  placeholder="NGSS Standards"
                  optionLabelProp="label"
                  showSearch={false}
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  showArrow
                  loading={loadingNGSSStateStandards}
                  value={investigationDraft?.nextGenerationStandards?.map((standard) => standard.code)?.sort()}
                  onChange={(change) => handleStandardsChange(change, INVESTIGATION_STANDARDS.NGSS)}
                  disabled={disabledNGStandardsField}
                  onDropdownVisibleChange={onDropdownVisibleChange}
                >
                  {Object.keys(ngssStandardsList)
                    .sort()
                    .map((grade, index) => {
                      return (
                        <>
                          <SelectOption key={index} value={grade} label={grade} disabled>
                            <strong>Grade {grade}</strong>
                          </SelectOption>
                          {sortBy(ngssStandardsList[grade], ['code', 'grade']).map((standard) => (
                            <SelectOption
                              value={standard.code}
                              label={standard.code}
                              key={standard.code}
                              style={{
                                maxWidth: '400px',
                              }}
                            >
                              {standard.code} - {standard.fullStatement}
                            </SelectOption>
                          ))}
                        </>
                      );
                    })}
                </Select>
              </>
            )}

            {investigationDraft.type === 'WORKSHOP' && (
              <>
                <Spacer />
                <h2>Workshop Elements</h2>
                <Select
                  placeholder="Workshop Elements"
                  optionLabelProp="label"
                  showSearch={false}
                  mode="multiple"
                  dropdownMatchSelectWidth={false}
                  showArrow
                  loading={loadingWorkshopElements}
                  value={investigationDraft.workshopElements?.map((cc) => cc.code)}
                  onChange={handleWorkshopElementsChange}
                >
                  {workshopElementsData?.getWorkshopElements.map((cc) => (
                    <SelectOption
                      value={cc.code}
                      label={cc.code}
                      key={cc.code}
                      disabled={
                        investigationDraft.workshopElements?.length === 5 &&
                        !investigationDraft.workshopElements.some((v) => v.code === cc.code)
                      }
                    >
                      ({cc.code}) {cc.text}
                    </SelectOption>
                  ))}
                </Select>
              </>
            )}
          </SettingsCollapsable>
          {!investigationDraft.isAssessment ? (
            <SettingsCollapsable title={`${settingsTitle} Settings`}>
              <h2>Investigation Stages</h2>
              {investigationDraft?.steps?.map((stage, i) => (
                <React.Fragment key={stage.id}>
                  <S.StageContainer
                    onClick={() =>
                      handleSaveThenNavigate(investigationDraft?.isAssessment ? 'settings' : 'stages', {
                        initialStageId: stage.id,
                      })
                    }
                  >
                    Stage {i + 1}: {stage.name}
                    <FiArrowRight size={18} />
                  </S.StageContainer>
                  <Spacer />
                </React.Fragment>
              ))}
            </SettingsCollapsable>
          ) : (
            <S.AssessmentSettingsContainer
              onClick={() =>
                handleSaveThenNavigate(investigationDraft?.isAssessment ? 'settings' : 'stages', {
                  initialStageId: investigationDraft?.steps?.[0]?.id,
                })
              }
            >
              <h2 data-cy="components-adieditassessmentcore-steps-0">{investigationDraft?.steps?.[0]?.name}</h2>
              <FiArrowRight size={18} />
            </S.AssessmentSettingsContainer>
          )}
          {!investigationDraft.isAssessment ? (
            <SettingsCollapsable title="Preview Settings">
              <S.StageContainer
                data-cy="components-adieditinvestigationcorepage-coresettingssider-edit-preview-button"
                onClick={() => handleSaveThenNavigate('preview')}
              >
                Edit Investigation Preview
                <FiArrowRight size={18} />
              </S.StageContainer>
            </SettingsCollapsable>
          ) : null}
        </S.SettingsContainer>
        {!investigationIsPublished &&
          !investigationDraft.firstPublishedAt &&
          (isAdiSuperAdmin || (isOrganizationAdiAdmin && user.subscription?.customLibrary)) && (
            <Popconfirm
              placement="bottomLeft"
              title={
                <p style={{ width: 320, marginBottom: 0 }}>
                  {`Do you really want to delete this ${
                    investigationDraft.isAssessment ? 'assessment' : 'investigation'
                  } draft? This is irreversible!`}
                </p>
              }
              onConfirm={handleDeleteInvestigation}
            >
              <Button theme={themeConfig.error} text={`Delete ${investigationTypeTitle}`} loading={loadingDeletion} />
            </Popconfirm>
          )}
        <S.ButtonsContainer>
          <Button
            data-cy="components-adieditinvestigationcorepage-coresettingssider-goback-button"
            text="Go Back"
            theme={themeConfig.noColor}
            block
            loading={loadingSaveInvestigation}
            onClick={handleGoBack}
          />
          <Spacer axis="horizontal" size={24} />
          <Button
            text="Save Settings"
            block
            onClick={handleSaveSettings}
            loading={loadingSaveInvestigation}
            data-cy="components-adieditinvestigationcorepage-coresettingssider-save-button"
          />
        </S.ButtonsContainer>
      </S.SiderContainer>
    </Sider>
  );
};

export default CoreSettingsSider;
