/* eslint-disable max-lines */
import { IQuestionnaireQuestion } from '../redux/modules/investigation';
import { UploadFile } from 'antd/lib/upload/interface';
import { GQL_InvestigationStandard } from './investigationStandard';
import { GQL_InvestigationFocus } from './investigationFocus';
import { GQL_InvestigationFormat } from './investigationFormat';

export interface GQL_ClassInvestigationStudent {
  id: string;
  startDate: number;
  endDate: number;
  isLocked: boolean;
}

export interface GQL_InvestigationDashboard {
  id: string;
  investigationId: string;
  title: string;
  description: string;
  completion: number;
  dueDate: number;
  engagementGrades: number;
  feedbackGrades: number;
  feedback: number;
  startDate: number;
  grade?: number;
  scoreType?: GQL_ScoreType;
  classId: string;
  discipline?: GQL_Discipline;
  isAssessment?: boolean;
  classStudent?: GQL_ClassInvestigationStudent;
  submissionVersion?: number;
  steps: {
    activities?: {
      completed?: boolean;
      completedAt?: number;
    }[];
    completed: boolean;
  }[];
}

export type GQL_Mode = 'INPERSON' | 'REMOTE' | 'ALL';
export type InvestigationType = 'STANDARD' | 'WORKSHOP';
export type WorkShopTemplate =
  | 'WORKSHOP_SCI'
  | 'WORKSHOP_NSW'
  | 'WORKSHOP_NSW_RT'
  | 'WORKSHOP_SD1'
  | 'WORKSHOP_SD2'
  | 'WORKSHOP_MCI'
  | 'WORKSHOP_MID1'
  | 'WORKSHOP_MID2'
  | 'WORKSHOP_EI'
  | 'WORKSHOP_ECI';

export interface GQL_InvestigationTeacherEntry {
  id: string;
  investigationId: string;
  title: string;
  grade: number;
  class: string;
  classId: string;
  classColorHex: string;
  completion: number;
  description: string;
  dueDate: number;
  startDate: number;
  isAssessment: boolean;
  discipline?: GQL_Discipline;
  submissionVersion: number;
}
export interface GQL_InvestigationTeacherEntryWithoutGrade {
  id: string;
  investigationId: string;
  title: string;
  class: string;
  classId: string;
  classColorHex: string;
  completion: number;
  description: string;
  dueDate: number;
  startDate: number;
  isAssessment: boolean;
  discipline?: GQL_Discipline;
  submissionVersion: number;
}

export interface GQL_InvestigationDisplay {
  id: string;
  title: string;
  imageUrl: string;
  kitUrl?: string;
  standardsUrl?: string;
  suppliesUrl?: string;
  letterHomeUrl?: string;
  startDate: number;
  dueDate: number;
  coreIdeas?: GQL_CodeTextPair[];
  practices?: GQL_CodeTextPair[];
  steps: GQL_InvestigationStep[];
  questionnaire: any;
  description: string;
  explanation: string;
  classId: string;
  discipline?: GQL_Discipline;
  type?: InvestigationType;
  availableForTrial?: boolean;
  crosscuttingConcepts?: GQL_CodeTextPair[];
  standards?: GQL_InvestigationStandard[];
  format?: GQL_InvestigationFormat;
  focus?: GQL_InvestigationFocus;
  time?: number;
  isAssessment?: boolean;
  isLocked?: boolean;
  classStudent?: GQL_ClassInvestigationStudent;
}

export interface GQL_InvestigationStep {
  id: string;
  name: string;
  previewText?: string;
  inPersonPreviewText?: string;
  remotePreviewText?: string;
  completed: boolean;
  startDate?: number;
  dueDate?: number;
  mode?: GQL_Mode;
  materials?: GQL_InvestigationCatalogStepMaterial[];
  modesAvailable?: GQL_Mode[];
  requiresPreviousStep?: boolean;
  activities: GQL_InvestigationActivity[];
}

export interface GQL_InvestigationActivity {
  id: string;
  stepId?: string;
  name: string;
  dueDate?: number | null;
  completed?: boolean;
  inPersonTeacher?: string;
  remoteTeacher?: string;
  safety?: string;
  help?: string;
  helpVideoUrl?: string;
  teacherHelpVideoUrl?: string;
  content: GQL_InvestigationContent[];
  contentInPerson?: GQL_InvestigationContent[];
  inPersonTeacherTips?: string;
}

export interface GQL_ActivityMaterials {
  price?: number;
  name?: string;
  amount?: number;
}

export interface GQL_InvestigationContent {
  title: string;
  blocks: GQL_InvestigationBlock[];
}

export type GQL_InvestigationBlockTypes =
  | 'Text'
  | 'O-List'
  | 'U-List'
  | 'Video'
  | 'Image'
  | 'Upload'
  | 'Reflection'
  | 'PeerReview'
  | 'Comment'
  | 'PeerReviewQuestionnaire'
  | 'ReflectionQuestionnaire'
  | 'Simulation'
  | 'Button'
  | 'TextSubmit'
  | 'ConceptEvaluation'
  | 'Canvas'
  | 'SortingBoard'
  | 'UploadFinalReport'
  | 'BudgetCalculator'
  | 'ConceptRating'
  | 'GroupBestConcept'
  | 'BestConceptPeerReview'
  | 'BestConceptReflection'
  | 'TextImage'
  | 'MultipleChoiceQuestion'
  | 'CategoryDrag'
  | 'ProcessDragDropAnswer'
  | 'MultipleChoicePictureQuestion'
  | 'ProcessDragDropPictureAnswer'
  | 'CategoryDragPicture';

export type GQL_InvestigationBlockValues =
  | ITextContentValue
  | IMediaContentValue
  | IUploadContentValue
  | IPeerReviewContentValue
  | IButtonContentValue
  | IReflectionContentValue
  | IOListContentValue
  | ICommentContentValue
  | IPeerReviewQuestionnaireContentValue
  | ICanvasContentValue
  | ISortingBoardContentValue
  | ICategoryDragContentValue
  | ICategoryDragContentValuePicture
  | IProcessDragDropAnswer
  | IConceptEvaluationContentValue
  | ITextSubmitContentValue
  | IProcessDragDropPictureAnswer;

export interface GQL_InvestigationBlock {
  type: GQL_InvestigationBlockTypes;
  userId?: string;
  userName?: string;
  columns?: number;
  values: GQL_InvestigationBlockValues[];
}

export interface ITextContentSpan {
  start: number;
  end: number;
  type:
    | 'strong'
    | 'italic'
    | 'hyperlink'
    | 'underline'
    | 'formula'
    | 'alignment'
    | 'unordered-list'
    | 'ordered-list'
    | 'list-item';
  url?: string;
  meta?: string;
}

export interface ITextSubmitContentValue {
  text?: string;
  title?: string;
  placeholder?: string;
  isUnlocked?: boolean;
}
export interface IBudgetCalculatorContentValue {
  materials?: GQL_ActivityMaterials[];
}
export interface ICanvasContentValue {
  titles?: string[];
  imageUrls?: string[];
  files?: UploadFile[];
  count?: number;
  url?: string;
  templateId?: number;
  MIMEtype?: string;
  width?: number;
}

export interface ISortingBoardTarget {
  title?: string;
  values?: string[];
}

export interface ISortingBoardContentValue {
  targets?: ISortingBoardTarget[];
}

export interface ICategoryDragContentValue {
  questionTitle?: string;
  targets?: ISortingBoardTarget[];
  answers?: { value: string[] }[];
}

export interface ICategoryDragContentValuePicture {
  questionTitle?: string;
  targets?: ISortingBoardTarget[];
  answers?: { value: string[] }[];
}

export interface IProcessDragDropAnswer {
  questionTitle?: string;
  directions?: string;
  scenarios?: string;
  targets?: ISortingBoardTarget[];
  answers?: { value: string[] }[];
}

export interface IProcessDragDropPictureAnswer {
  questionTitle?: string;
  directions?: string;
  scenarios?: string;
  targets?: ISortingBoardTarget[];
  answers?: { value: string[] }[];
}

export interface ITextContentValue {
  type?: 'paragraph' | 'header1' | 'header2' | 'header3' | 'button'; // default to paragraph
  text: string;
  spans: ITextContentSpan[];
  ratio?: number;
  optionLabel?: string;
  targetAnswer?: boolean;
  selectedAnswer?: boolean;
  file?: File;
  url?: string;
  alt?: string;
  fileName?: string;
}

export interface IButtonContentValue {
  text: string;
  color?: string;
  url?: string;
  file?: File;
}

export interface IOListContentValue {
  text: string;
  spans: {
    start: number;
    end: number;
    type: 'strong' | 'italic' | 'hyperlink';
    url?: string;
  }[];
}

export interface IMediaContentValue {
  url?: string;
  alt?: string;
  file?: File;
  ratio?: number;
  size?: 'SMALL' | 'LARGE' | 'EXTRALARGE';
}

export interface IUploadContentValue {
  type: 'Recording' | 'File';
  url?: string;
  alt?: string;
  MIMEtype?: string;
  hidden?: boolean;
}

export interface IPeerReviewContentValue {
  stepId?: string;
  studentId?: string;
  completedAt?: number;
  readyToReview?: boolean;
  activityId?: string;
  rating?: number;
}

export interface IConceptEvaluationContentValue {
  stepId?: string;
  studentId?: string;
  completedAt?: number;
  readyToReview?: boolean;
  question?: IQuestionnaireQuestion;
}

export interface IPeerReviewQuestionnaireContentValue {
  stepId: string;
  studentId: string;
  completedAt?: number;
  readyToReview?: boolean;
  questionnaire?: {
    title?: string;
    feedback?: string;
    questions: {
      question?: string;
      answer?: number;
    }[];
  }[];
}

export interface ICommentContentValue {
  label: string;
}

export interface IReflectionContentValue {
  peerReviewId: string;
  rating?: number;
  reflection?: string;
  reflectionCompletedAt?: number;
  review?: {
    id: string;
    fileUrl: string;
    reflection: string;
    studentId: string;
    rating: number;
    stepId: string;
    fileMIMEtype?: string;
    comments: IReflectionComment[];
    completedAt?: number;
    reflectionCompletedAt?: number;
  };
}

export interface IReflectionQuestionnaireContentValue {
  peerReviewId: string;
  rating?: number;
  reflection?: string;
  reflectionCompletedAt?: number;
  review: {
    id: string;
    fileUrl: string;
    reflection: string;
    studentId: string;
    rating: number;
    fileMIMEtype?: string;
    stepId: string;
    questionnaire: {
      id: string;
      title: string;
      feedback: string;
      questionIndex: number;
      answers: {
        id: string;
        answerIndex: number;
        answer: number;
      }[];
    }[];
  };
  questions?: {
    question: string;
    answer?: string;
  }[];
}

export interface IReflectionComment {
  id: string;
  text: string;
  coordinates?: {
    x?: number;
    y?: number;
    ts?: number;
    page?: number;
  };
  flags: {
    count: number;
    flagged: boolean;
  };
  hasFlagged: boolean;
  likes: number;
  hasLiked: boolean;
}

export interface GQL_SubmitCommentVariables {
  activityId: string;
  text: string;
  classId?: string;
  commentParent?: string;
  coordinates?: {
    page?: number;
    x?: number;
    y?: number;
    ts?: number;
  };
}

export interface GQL_Comment {
  id: string;
  createdAt: number;
  avatar: string;
  text: string;
  activityId: string;
  reflection?: string;
  author: string;
  userId?: string;
  responses: GQL_Comment[];
  group?: string;
  flags?: {
    count: number;
    flagged: boolean;
  };
  likes?: {
    count: number;
    liked: boolean;
  };
  coordinates?: {
    x?: number;
    y?: number;
    ts?: number;
    page?: number;
  };
}

export interface GQL_CodeTextPair {
  code: string;
  text: string;
}

export interface GQL_InvestigationCatalog {
  id: string;
  title: string;
  description: string;
  isDraft?: boolean;
  isTemplate?: boolean;
  discipline?: GQL_Discipline;
  coreIdeas?: GQL_CodeTextPair[];
  practices?: GQL_CodeTextPair[];
  workshopElements?: GQL_CodeTextPair[];
  crosscuttingConcepts?: GQL_CodeTextPair[];
  markedForDeletion?: number;
  createdAt: number;
  firstPublishedAt?: number;
  type: InvestigationType;
  availableForTrial?: boolean;
  kitUrl?: string;
  teacherFavorite?: boolean;
  steps: {
    activities?: GQL_InvestigationCatalogActivityEntry[];
    id: string;
    mode?: GQL_Mode;
  }[];
  standards?: GQL_InvestigationStandard[];
  nextGenerationStandards?: GQL_InvestigationStandard[];
  format?: GQL_InvestigationFormat;
  focus?: GQL_InvestigationFocus;
  time?: number;
}

export interface GQL_PeerReviewQuestionnaire {
  id: string;
  title: string;
  feedback: string;
  questionIndex: number;
  answers: {
    id?: string;
    answerIndex: number;
    answer: number;
  }[];
}

export interface GQL_PeerReview {
  id: string;
  stepId?: string;
  studentId?: string;
  userId?: string;
  fileUrl?: string;
  fileMIMEtype?: string;
  createdAt?: number;
  completedAt?: number;
  reflectionCompletedAt?: number;
  comments?: GQL_Comment[];
  questionnaire: GQL_PeerReviewQuestionnaire[];
}

export interface GQL_EvaluationAnswers {
  id: string;
  feedback: string;
  completedAt?: number;
  answers: {
    answerValue: string;
    questionIndex: number;
  }[];
  evaluatorId: string;
  evaluatedUserId: string;
  conceptActivityId?: string;
}

export interface GQL_Concept {
  files?: {
    fileUrl: string;
    fileMIMEtype: string;
  }[];
  materials: GQL_ActivityMaterials[];
  question: {
    title: string;
    subquestions: {
      text: string;
    }[];
  };
  evaluation?: GQL_EvaluationAnswers;
}

export interface GQL_EditInvestigationDurationInput {
  classId: string;
  investigationId: string;
  endDate: number;
  startDate: number;
}

export interface GQL_EditInvestigationIsTemplate {
  investigationId: string;
  isTemplate: boolean;
}

export interface GQL_EditAssessmentPerStudentInput {
  classId: string;
  investigationId: string;
  studentId: string;
  key?: string;
  value?: number | boolean;
  submissionVersion: number;
}

export interface GQL_BulkEditAssessmentPerStudentInput {
  classInvestigationId: string;
  investigationId?: string;
  studentIds: string[];
  startDate?: number;
  endDate?: number;
  isLocked?: boolean;
}

export interface GQL_Discipline {
  id: string;
  name: string;
  gradeBand: string;
  subject: string;
  coreIdeas?: GQL_CodeTextPair[];
}

export interface GQL_InvestigationMetadataCodebook {
  disciplines: GQL_Discipline[];
  coreIdeas: GQL_CodeTextPair[];
  practices: GQL_CodeTextPair[];
  crosscuttingConcepts: GQL_CodeTextPair[];
}

export interface GQL_InvestigationCatalogActivityEntry {
  id: string;
  name: string;
  order: number;
  stepId?: string;
  inPersonTeacher?: string;
  inPersonTeacherTips?: string;
  remoteTeacher?: string;
  safety?: string;
  help?: string;
  content: GQL_InvestigationContent[];
  helpVideoUrl?: string;
  teacherHelpVideoUrl?: string;
  contentInPerson: GQL_InvestigationContent[];
  nextGenerationStandards?: GQL_InvestigationStandard[];
  texasStandards?: GQL_InvestigationStandard[];
}

export interface GQL_InvestigationCatalogStepMaterial {
  id: string;
  filename: string;
  MIMEType: string;
  url: string;
  created: number;
}

export interface GQL_InvestigationCatalogStepEntry {
  id: string;
  name: string;
  previewText: string;
  inPersonPreviewText: string;
  remotePreviewText: string;
  order?: number;
  mode?: GQL_Mode;
  materials: GQL_InvestigationCatalogStepMaterial[];
  activities: GQL_InvestigationCatalogActivityEntry[];
}

export interface PaginatorParams {
  page?: number;
  pageSize?: number;
  sortDirection?: string;
}

export interface PaginatorResult {
  total: number;
  page: number;
  pageSize: number;
  nextPage: number;
}
export interface GQL_InvestigationCatalogEntry {
  id: string;
  title: string;
  organization: { id: string; name: string };
  imageUrl: string;
  standardsUrl: string;
  suppliesUrl: string;
  letterHomeUrl: string;
  createdAt: number;
  isDraft: boolean;
  markedForDeletion?: number;
  firstPublishedAt?: number;
  description: string;
  explanation: string;
  duration: string;
  discipline?: GQL_Discipline;
  coreIdeas?: GQL_CodeTextPair[];
  workshopElements?: GQL_CodeTextPair[];
  practices?: GQL_CodeTextPair[];
  crosscuttingConcepts?: GQL_CodeTextPair[];
  steps: GQL_InvestigationCatalogStepEntry[];
  type: InvestigationType;
  availableForTrial?: boolean;
  kitUrl?: string;
  teacherNotesPdf?:string
  teacherFavorite?: boolean;
  standards?: GQL_InvestigationStandard[];
  nextGenerationStandards?: GQL_InvestigationStandard[];
  isAssessment: boolean;
  isTemplate?: boolean;
  focus?: GQL_InvestigationFocus;
  format?: GQL_InvestigationFormat;
  time?: number;
}

export interface GQL_InvestigationCatalogEntryPaginated extends PaginatorResult {
  list: GQL_InvestigationCatalogEntry[];
}

export interface GQL_FinalReportQuestionnaireQuestion {
  index: number;
  question: string;
  answer: number;
}

export interface GQL_FinalReportQuestionnaire {
  index: number;
  title: string;
  questions: GQL_FinalReportQuestionnaireQuestion[];
  feedback: string;
}

export interface GQL_FinalReportQuestionnaireQuestionInput {
  index: number;
  answer: number;
  isValid: boolean;
}

export enum GQL_ScoreType {
  SCORE_ONLY = 'SCORE_ONLY',
  FEEDBACK_ONLY = 'FEEDBACK_ONLY',
  SCORE_FEEDBACK = 'SCORE_FEEDBACK',
}

export interface GQL_GradedFinalReportQuestionnaire {
  grade: number;
  scoreType?: GQL_ScoreType;
  questionnaire: GQL_FinalReportQuestionnaire[];
}

export interface GQL_FinalReportQuestionnaireInput {
  index: number;
  feedback: string;
  questions: GQL_FinalReportQuestionnaireQuestionInput[];
}

export interface GQL_EngagementQuestion {
  index: number;
  question: string;
  answer: number;
}

export interface GQL_GradedEngagementQuestions {
  grade: number;
  questions: GQL_EngagementQuestion[];
}

export interface GQL_EngagementQuestionInput {
  index: number;
  answer: number;
  isValid: boolean;
}

export interface GQL_DeleteCommentResponse {
  deleted: boolean;
  commentId: string;
}

export interface Duration {
  startDate: string;
  endDate: string;
}

export interface FilterData {
  organizations: boolean;
  teachers: boolean;
  gradeBand?: string;
  disciplineIds?: number[];
  investigationType?: string;
  organizationId?: string;
  teacherId?: string;
  tagIds?: string[];
}

export enum INVESTIGATION_TABS {
  STANDARD = 'standard',
  WORKSHOP = 'workshop',
  ORG_LIBRARY = 'organization-library',
  ADI_LIBRARY = 'adi-library',
}

export type InvestigationCatalogPaginatedParams = {
  isAssessment: boolean;
  isTemplate?: boolean;
  investigationType?: InvestigationType;
  organizationId?: string | null;
  grade?: string;
  version?: string;
  subject?: string;
  discipline?: string;
  status?: string;
  teacherFavorite?: string;
  trial?: string;
  searchText?: string;
  mode?: string;
  state?: string;
  firstPublishedAt?: string;
  coreIdea?: string;
  standard?: string;
  crossCuttingConcept?: string;
  focus?: string;
  practice?: string;
  type?: string;
} & PaginatorParams;