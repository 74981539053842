/* eslint-disable max-lines */
/* eslint-disable complexity */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import Form from '../../../shared/Form';
import * as S from './styles';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useForm } from 'antd/lib/form/Form';
import { GQL_OrganizationDetailsResponse, GQL_OrganizationResponse } from '../../../types/organization';
import { Col, Row, Form as AntdForm, message, Popconfirm, Tooltip, Tabs, Divider } from 'antd';
import RangePicker from '../../../shared/RangePicker';
import { formatDateTime, toDateFormat } from '../../../utils/date';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import { useAuth } from '../../../hooks/useAuth';
import { useMemo } from 'react';
import Switch from '../../../shared/Switch';
import { FiHelpCircle } from 'react-icons/fi';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { MdCancel } from 'react-icons/md';
import useFetchStatesData from '../../../hooks/useFetchStatesData';
import { SUBJECT_TYPES } from '../../../types/subjects';
import { gradeBands, grades } from '../../../utils/constants';
import { useEnabledAssessments } from '../../../hooks/useEnabledAssessments';

const { TabPane } = Tabs;

interface Props {
  organization?: GQL_OrganizationDetailsResponse;
  refetchOrganization: () => void;
  teachers?: GQL_UserDetailsResponse[];
}

const MAP_ROSTER_LABEL: { [key: string]: string } = {
  '': 'Source',
  classlink: 'Source',
  clever: 'District',
};

// eslint-disable-next-line max-statements
const OrganizationInfo: React.FC<Props> = (props) => {
  const { organization, refetchOrganization, teachers } = props;
  const [changeNameVisible, setChangeNameVisible] = useState(false);
  const [changeSubscriptionVisible, setChangeSubscriptionVisible] = useState(false);
  const [changeLicensesVisible, setChangeLicensesVisible] = useState(false);
  const [changeTAQuantityVisible, setChangeTAQuantityVisible] = useState(false);
  const [changeWritersQuantityVisible, setChangeWritersQuantityVisible] = useState(false);
  const [changeInvestigationLibraryLimitVisible, setChangeInvestigationLibraryLimitVisible] = useState(false);
  const [changeIdentitySourceVisible, setChangeIdentitySourceVisible] = useState(false);
  const [changeStateStandardsVisible, setChangeStateStandardsVisible] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [customLibrary, setCustomLibrary] = useState(false);
  const [readerPermissions, setReaderPermissions] = useState(false);
  const [teachersCanDoReviews, setTeachersCanDoReviews] = useState(false);
  const [blockAdiInvestigations, setBlockAdiInvestigations] = useState(false);
  const [disableCustomGradeBands, setDisableCustomGradeBands] = useState(true);
  const [form] = useForm();

  const { isAdiSuperAdmin, isAdiAdmin, isOrganizationAdiAdmin } = useAuth();
  const [customTAPermisions, setCustomTAPermisions] = useState(false);
  const [reportPermission, setReportPermission] = useState(false);
  const [activeTab, setActiveTab] = useState('settings');

  const [subscriptionDuration, setSubscriptionDuration] = useState<{ startDate: string; endDate: string }>({
    startDate: formatDateTime(organization?.subscription?.startDate),
    endDate: formatDateTime(organization?.subscription?.endDate),
  });

  const [submitEditSubscription] = useMutation(gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscription, {
    onCompleted: ({ updateSubscription }: { updateSubscription: boolean }) => {
      if (updateSubscription) {
        message.success(`${organization?.name} subscription updated successfully`);
      } else message.error(`Error updating subscription for ${organization?.name}`);
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  // fetch states data from backend
  const { statesData } = useFetchStatesData();

  const editSubscription = useCallback(
    (
      trial: boolean,
      cLibrary: boolean,
      readerPermissions: boolean,
      teachersCanDoReviews: boolean,
      reportPermission: boolean,
    ) => {
      const values = form.getFieldsValue();
      submitEditSubscription({
        variables: {
          data: {
            id: organization?.subscription.id,
            organizationId: organization?.id,
            startDate: new Date(toDateFormat(values.subscriptionData.startDate)).getTime(),
            endDate: new Date(toDateFormat(values.subscriptionData.endDate)).getTime(),
            invitesAvailable: +values.invitesAvailable,
            writerQuantity: +values.writers,
            investigationLibraryLimit: +values.invLibLimit,
            isTrial: trial,
            customLibrary: cLibrary,
            readerPermissions: readerPermissions,
            taCustomPermissions: values.taCustomPermissions,
            teachersCanDoReviews: teachersCanDoReviews,
            reportPermission,
          },
        },
      });
    },
    [form, organization, submitEditSubscription],
  );

  const handleEditingDatesButton = useCallback(() => {
    if (changeSubscriptionVisible) {
      editSubscription(isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission);
    }
    setChangeSubscriptionVisible(!changeSubscriptionVisible);
  }, [
    editSubscription,
    changeSubscriptionVisible,
    isTrial,
    customLibrary,
    readerPermissions,
    teachersCanDoReviews,
    reportPermission,
  ]);

  const handleCancelEditingDatesButton = useCallback(() => {
    const subscriptionDuration = {
      startDate: formatDateTime(organization?.subscription?.startDate),
      endDate: formatDateTime(organization?.subscription?.endDate),
    };
    setSubscriptionDuration(subscriptionDuration);
    setChangeSubscriptionVisible(!changeSubscriptionVisible);
  }, [organization, changeSubscriptionVisible]);

  const handleEditingLicensesButton = useCallback(() => {
    if (changeLicensesVisible) {
      const values = form.getFieldsValue();
      if (+values.invitesAvailable >= (teachers?.length || 0))
        editSubscription(isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission);
      else {
        message.error('It would be more teachers than subscription slots!');
        form.setFieldsValue({ invitesAvailable: organization?.subscription.invitesAvailable });
      }
    }
    setChangeLicensesVisible(!changeLicensesVisible);
  }, [
    changeLicensesVisible,
    form,
    teachers,
    editSubscription,
    isTrial,
    organization,
    customLibrary,
    readerPermissions,
    teachersCanDoReviews,
    reportPermission,
  ]);

  const handleEditingWritersButton = useCallback(() => {
    if (changeWritersQuantityVisible) {
      editSubscription(isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission);
    }
    setChangeWritersQuantityVisible(!changeWritersQuantityVisible);
  }, [
    changeWritersQuantityVisible,
    editSubscription,
    isTrial,
    customLibrary,
    readerPermissions,
    teachersCanDoReviews,
    reportPermission,
  ]);

  const handleEditingInvestigationLibraryLimitButton = useCallback(() => {
    if (changeInvestigationLibraryLimitVisible) {
      editSubscription(isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission);
    }
    setChangeInvestigationLibraryLimitVisible(!changeInvestigationLibraryLimitVisible);
  }, [
    changeInvestigationLibraryLimitVisible,
    editSubscription,
    isTrial,
    customLibrary,
    readerPermissions,
    teachersCanDoReviews,
    reportPermission,
  ]);

  const handleOkIsTrial = useCallback(() => {
    editSubscription(!isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission);
    setIsTrial(!isTrial);
  }, [editSubscription, isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission]);

  const handleOkCustomLibrary = useCallback(() => {
    editSubscription(isTrial, !customLibrary, readerPermissions, teachersCanDoReviews, reportPermission);
    setCustomLibrary(!customLibrary);
  }, [editSubscription, isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission]);

  const handleOkReportPermission = useCallback(() => {
    editSubscription(isTrial, customLibrary, readerPermissions, teachersCanDoReviews, !reportPermission);
    setReportPermission(!reportPermission);
  }, [editSubscription, isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission]);

  const handleOkReaderPermissions = useCallback(() => {
    editSubscription(isTrial, customLibrary, !readerPermissions, teachersCanDoReviews, reportPermission);
    setReaderPermissions(!readerPermissions);
  }, [editSubscription, isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission]);

  const handleOkTeacherReviews = useCallback(() => {
    editSubscription(isTrial, customLibrary, readerPermissions, !teachersCanDoReviews, reportPermission);
    setTeachersCanDoReviews(!teachersCanDoReviews);
  }, [editSubscription, isTrial, customLibrary, readerPermissions, teachersCanDoReviews, reportPermission]);

  const [submitEditOrganization] = useMutation(gqlSchema.OrganizationSchema.mutations.EDIT.editOrganization, {
    onCompleted: ({ editOrganization }: { editOrganization: GQL_OrganizationResponse }) => {
      message.success(`${editOrganization.name} updated successfully`);
    },
    onError: (error) => {
      message.error(error.message);
    },
    update(cache, { data }) {
      if (data) {
        const organizationResponse = {
          __typename: 'OrganizationDetailsResponse',
          id: data.editOrganization.id,
        };
        const values = form.getFieldsValue();

        cache.modify({
          id: cache.identify(organizationResponse),
          fields: {
            name() {
              return values.name;
            },
          },
        });
      }
    },
  });

  const [submitEditBlockAdiInvestigation] = useMutation(
    gqlSchema.OrganizationSchema.mutations.EDIT.editBlockAdiInvestigation,
    {
      onCompleted: ({ editBlockAdiInvestigation }: { editBlockAdiInvestigation: GQL_OrganizationResponse }) => {
        message.success(`${editBlockAdiInvestigation.name} updated successfully`);
      },
      onError: (error) => {
        message.error(error.message);
      },
      update(cache, { data }) {
        if (data) {
          const organizationResponse = {
            __typename: 'OrganizationDetailsResponse',
            id: data.editBlockAdiInvestigation.id,
          };
          const values = form.getFieldsValue();

          cache.modify({
            id: cache.identify(organizationResponse),
            fields: {
              name() {
                return values.name;
              },
            },
          });
        }
      },
    },
  );

  const handleEditingTAQuantityButton = useCallback(() => {
    if (changeTAQuantityVisible) {
      const values = form.getFieldsValue();
      submitEditOrganization({
        variables: {
          data: {
            id: organization?.id,
            name: values.name,
            defaultTAQuantity: +values.defaultTAQuantity,
            roster: {
              type: values.roster.type,
              id: values.roster.id,
            },
          },
        },
      });
    }
    setChangeTAQuantityVisible(!changeTAQuantityVisible);
  }, [changeTAQuantityVisible, form, organization, submitEditOrganization]);

  const handleEditingIdentitySourceButton = useCallback(() => {
    if (changeIdentitySourceVisible) {
      const values = form.getFieldsValue();
      submitEditOrganization({
        variables: {
          data: {
            id: organization?.id,
            name: values.name,
            defaultTAQuantity: +values.defaultTAQuantity,
            roster: {
              type: values.roster.type,
              id: values.roster.id,
            },
          },
        },
      });
    }
    setChangeIdentitySourceVisible(!changeIdentitySourceVisible);
  }, [changeIdentitySourceVisible, form, organization, submitEditOrganization]);

  const [submitEditSubjects, { loading: loadingSubjectsSubmission }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscriptionSubjects,
    {
      onCompleted: ({ updateSubscriptionSubjects }) => {
        if (updateSubscriptionSubjects) message.success(`Subscription subjects updated successfully`);
        else message.error(`Error updating subscription subjects`);

        refetchOrganization();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitEditGradeBand, { loading: loadingGradeBandSubmission }] = useMutation(
    gqlSchema.UserSchema.mutations.SUBSCRIPTION.updateSubscriptionGradeBands,
    {
      onCompleted: ({ updateSubscriptionGradeBands }) => {
        if (updateSubscriptionGradeBands) message.success(`Subscription Grade Levels updated successfully`);
        else message.error(`Error updating subscription grade levels`);

        refetchOrganization();
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const resetCustomGrades = useCallback(
    (subject, subjectList) => {
      if (
        subject === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION &&
        !subjectList.includes(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION)
      ) {
        const removedCustomGradeBands =
          organization?.subscription?.allowedGradeBands?.filter((grade) => {
            return !grades.includes(grade);
          }) || [];
        submitEditGradeBand({
          variables: { subscriptionId: organization?.subscription?.id, gradeBands: removedCustomGradeBands },
        });
      }
    },
    [organization, submitEditGradeBand],
  );

  const handleSubjectsChange = useCallback(
    async (subject: string) => {
      const newSelectedSubjectsList = toggleElementInList(organization?.subscription?.allowedSubjects, subject);
      await submitEditSubjects({
        variables: { subscriptionId: organization?.subscription?.id, subjects: newSelectedSubjectsList },
      });
      resetCustomGrades(subject, newSelectedSubjectsList);
    },
    [organization, submitEditSubjects, resetCustomGrades],
  );

  const handleEditingStateStandardsButton = useCallback(() => {
    if (changeStateStandardsVisible) {
      const values = form.getFieldsValue();

      let allowedStates: {
        id: string | undefined;
        name: /* eslint-disable complexity */ string | undefined;
        postalCode: string | undefined;
      }[] = [];

      if (values.allowedStates) {
        // create a list of edited allowedStates
        allowedStates = [values.allowedStates].map((stateName: string) => {
          const state = statesData.find((state) => state.name === stateName);
          return {
            id: state?.id,
            name: state?.name,
            postalCode: state?.postalCode,
          };
        });
      }

      const data = {
        allowedStates,
        name: organization?.name,
        id: organization?.id,
        defaultTAQuantity: +values.defaultTAQuantity,
      };

      submitEditOrganization({
        variables: {
          data,
        },
      });
    }
    setChangeStateStandardsVisible(!changeStateStandardsVisible);
  }, [changeStateStandardsVisible, form, organization, submitEditOrganization, statesData]);

  const handleOkBlockLibrary = useCallback(() => {
    setBlockAdiInvestigations(!blockAdiInvestigations);
    submitEditBlockAdiInvestigation({
      variables: {
        data: {
          organizationId: organization?.id,
          blockAdiInvestigations: !blockAdiInvestigations,
        },
      },
    });
  }, [submitEditBlockAdiInvestigation, organization, blockAdiInvestigations]);

  useEffect(() => {
    if (organization) {
      form.setFieldsValue({
        name: organization?.name,
        subscriptionData: {
          startDate: formatDateTime(organization?.subscription?.startDate),
          endDate: formatDateTime(organization?.subscription?.endDate),
        },
        writers: organization.subscription?.writerQuantity,
        invLibLimit: organization.subscription?.investigationLibraryLimit,
        invitesAvailable: organization.subscription?.invitesAvailable,
        defaultTAQuantity: organization.defaultTAQuantity,
        roster: {
          type: organization.classLinkOrgId ? 'classlink' : organization.cleverOrgId ? 'clever' : 'adi',
          id: organization.classLinkOrgId ?? organization.cleverOrgId,
        },
        allowedStates: organization.allowedStates.map((state) => state.name),
      });

      setIsTrial(organization.subscription?.isTrial);
      setCustomLibrary(organization.subscription?.customLibrary);
      setReaderPermissions(!!organization.subscription?.readerPermissions);
      setTeachersCanDoReviews(!!organization.subscription?.teachersCanDoReviews);
      setCustomTAPermisions(!!organization?.subscription?.taCustomPermissions);
      setReportPermission(!!organization?.subscription?.reportPermission);
      setBlockAdiInvestigations(organization.blockAdiInvestigations || false);
    }
  }, [form, organization]);

  const toggleElementInList = (list: string[] | undefined, value: string) => {
    let currentList = [...(list || [])];

    if (currentList.includes(value)) {
      currentList.splice(currentList.indexOf(value), 1);
    } else {
      currentList.push(value);
    }

    return [...new Set(currentList)];
  };

  const handleGradesChange = useCallback(
    (gradeBand: string) => {
      const newSelectedGradeBandsList = toggleElementInList(organization?.subscription?.allowedGradeBands, gradeBand);
      submitEditGradeBand({
        variables: { subscriptionId: organization?.subscription?.id, gradeBands: newSelectedGradeBandsList },
      });
    },
    [organization, submitEditGradeBand],
  );

  useEffect(() => {
    if (!organization) return;

    const { allowedSubjects } = organization?.subscription || {};
    const selectedSubjects = allowedSubjects || [];
    const texasInvestigationActive = selectedSubjects.includes(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION);

    const otherSelected = Boolean(selectedSubjects.length > 1);
    const disabled = texasInvestigationActive ?? (!texasInvestigationActive || otherSelected);
    setDisableCustomGradeBands(!disabled);
  }, [organization]);

  const handleEditingButton = useCallback(
    (visibility: boolean, setVisibility: Dispatch<SetStateAction<boolean>>) => {
      if (visibility) {
        const values = form.getFieldsValue();
        submitEditOrganization({
          variables: {
            data: {
              id: organization?.id,
              name: values.name,
              defaultTAQuantity: +values.defaultTAQuantity,
              roster: {
                type: values.roster.type,
                id: values.roster.id,
              },
            },
          },
        });
      }
      setVisibility(!visibility);
    },
    [form, submitEditOrganization, organization],
  );

  const handleCancelEditingButton = useCallback((setVisibility: Dispatch<SetStateAction<boolean>>) => {
    setVisibility(false);
  }, []);

  const [submitToggleAssistantCustomPermission] = useMutation(
    gqlSchema.OrganizationSchema.mutations.EDIT.toogleTACustomPermission,
    {
      onCompleted: ({ toggleTACustomPermissions }: { toggleTACustomPermissions: boolean }) => {
        if (toggleTACustomPermissions) {
          message.success(`Permission updated!`);
          refetchOrganization();
        } else message.error(`Error while granting permission`);
      },
      onError: (err) => {
        message.error(err.message || 'Unexpected Error');
      },
    },
  );

  const { subjectsList, texasEditionEnabled } = useEnabledAssessments({
    allowedSubjects: organization?.subscription?.allowedSubjects || [],
    allowedStates: form.getFieldValue('allowedStates'),
  });

  const customLibraryControls = useMemo(
    () => (
      <Row gutter={24} style={{ padding: '12px 24px 24px 24px' }}>
        <S.CDivider />

        <S.Line md={24} lg={6}>
          <S.TitleInput>Library Investigations Limit</S.TitleInput>
          <Row>
            <Col span={changeInvestigationLibraryLimitVisible ? 16 : 20}>
              <AntdForm.Item
                name="invLibLimit"
                initialValue={10}
                rules={[
                  {
                    required: true,
                    message: 'Please add Library Investigations Limit!',
                  },
                ]}
              >
                <S.SInput
                  type="number"
                  min={1}
                  bordered={changeInvestigationLibraryLimitVisible}
                  disabled={!changeInvestigationLibraryLimitVisible}
                />
              </AntdForm.Item>
            </Col>

            {(isAdiSuperAdmin || isAdiAdmin) && (
              <Col span={changeInvestigationLibraryLimitVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                <S.EditButton
                  type="primary"
                  shape="circle"
                  icon={changeInvestigationLibraryLimitVisible ? <BiCheck /> : <BiEdit />}
                  onClick={handleEditingInvestigationLibraryLimitButton}
                  disabled={!customLibrary}
                />
              </Col>
            )}

            {(isAdiSuperAdmin || isAdiAdmin) && changeInvestigationLibraryLimitVisible && (
              <Col span={changeInvestigationLibraryLimitVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                <S.CancelButton
                  type="ghost"
                  shape="circle"
                  icon={<MdCancel />}
                  onClick={() => handleCancelEditingButton(setChangeInvestigationLibraryLimitVisible)}
                />
              </Col>
            )}
          </Row>
        </S.Line>

        <S.Line md={24} lg={6}>
          <S.TitleInput>Max. Writers Quantity</S.TitleInput>
          <Row>
            <Col span={changeWritersQuantityVisible ? 16 : 20}>
              <AntdForm.Item
                name="writers"
                initialValue={5}
                rules={[
                  {
                    required: true,
                    message: 'Please add Writers Quantity!',
                  },
                ]}
              >
                <S.SInput
                  type="number"
                  min={1}
                  bordered={changeWritersQuantityVisible}
                  disabled={!changeWritersQuantityVisible}
                />
              </AntdForm.Item>
            </Col>

            {(isAdiSuperAdmin || isAdiAdmin) && (
              <Col span={changeWritersQuantityVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                <S.EditButton
                  type="primary"
                  shape="circle"
                  icon={changeWritersQuantityVisible ? <BiCheck /> : <BiEdit />}
                  onClick={handleEditingWritersButton}
                  disabled={!customLibrary}
                />
              </Col>
            )}

            {(isAdiSuperAdmin || isAdiAdmin) && changeWritersQuantityVisible && (
              <Col span={changeWritersQuantityVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                <S.CancelButton
                  type="ghost"
                  shape="circle"
                  icon={<MdCancel />}
                  onClick={() => handleCancelEditingButton(setChangeWritersQuantityVisible)}
                />
              </Col>
            )}
          </Row>
        </S.Line>

        <S.Line md={24} lg={6}>
          <S.TitleInput>Identity Source</S.TitleInput>
          <Row>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.roster?.type !== currentValues.roster?.type}
            >
              {({ getFieldValue }) => (
                <>
                  <Col span={getFieldValue('roster')?.type === 'adi' ? (changeIdentitySourceVisible ? 16 : 20) : 24}>
                    <AntdForm.Item
                      name={['roster', 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select an Identity Source!',
                        },
                      ]}
                    >
                      <Select
                        disabled={!changeIdentitySourceVisible}
                        bordered={changeIdentitySourceVisible}
                        showArrow={changeIdentitySourceVisible}
                      >
                        <SelectOption value="adi">ADI</SelectOption>
                        <SelectOption value="classlink">CLASSLINK</SelectOption>
                        <SelectOption value="clever">CLEVER</SelectOption>
                      </Select>
                    </AntdForm.Item>
                  </Col>

                  {getFieldValue('roster')?.type === 'adi' ? (
                    <>
                      {(isAdiSuperAdmin || isAdiAdmin) && (
                        <Col span={changeIdentitySourceVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                          <S.EditButton
                            type="primary"
                            shape="circle"
                            icon={changeIdentitySourceVisible ? <BiCheck /> : <BiEdit />}
                            onClick={handleEditingIdentitySourceButton}
                          />
                        </Col>
                      )}

                      {(isAdiSuperAdmin || isAdiAdmin) && changeIdentitySourceVisible && (
                        <Col span={changeIdentitySourceVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                          <S.CancelButton
                            type="ghost"
                            shape="circle"
                            icon={<MdCancel />}
                            onClick={() => handleCancelEditingButton(setChangeIdentitySourceVisible)}
                          />
                        </Col>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </AntdForm.Item>
          </Row>
        </S.Line>

        <S.Line md={24} lg={6}>
          <S.TitleInput>State Standards</S.TitleInput>
          <Row>
            <AntdForm.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.allowedStates !== currentValues.allowedStates}
            >
              <Col span={changeStateStandardsVisible ? 16 : 20}>
                <AntdForm.Item name={['allowedStates']}>
                  <Select
                    disabled={!changeStateStandardsVisible}
                    bordered={changeStateStandardsVisible}
                    showArrow={changeStateStandardsVisible}
                  >
                    <SelectOption key={'empty-state-id'} value={''}>
                      {''}
                    </SelectOption>
                    {statesData.map((state) => {
                      return (
                        <SelectOption key={state.id} value={state.name}>
                          {state.name}
                        </SelectOption>
                      );
                    })}
                  </Select>
                </AntdForm.Item>
              </Col>

              {(isAdiSuperAdmin || isAdiAdmin) && (
                <Col span={changeStateStandardsVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                  <S.EditButton
                    type="primary"
                    shape="circle"
                    icon={changeStateStandardsVisible ? <BiCheck /> : <BiEdit />}
                    onClick={handleEditingStateStandardsButton}
                  />
                </Col>
              )}

              {(isAdiSuperAdmin || isAdiAdmin) && changeStateStandardsVisible && (
                <Col span={changeStateStandardsVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                  <S.CancelButton
                    type="ghost"
                    shape="circle"
                    icon={<MdCancel />}
                    onClick={() => handleCancelEditingButton(setChangeStateStandardsVisible)}
                  />
                </Col>
              )}
            </AntdForm.Item>
          </Row>
        </S.Line>

        <AntdForm.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.roster?.type !== currentValues.roster?.type}
        >
          {({ getFieldValue }) =>
            getFieldValue('roster')?.type !== 'adi' ? (
              <S.Line md={24} lg={isTrial ? 5 : 6}>
                <S.TitleInput>{MAP_ROSTER_LABEL[getFieldValue('roster')?.type]} ID</S.TitleInput>
                <Row>
                  <Col span={changeIdentitySourceVisible ? 16 : 20}>
                    <AntdForm.Item
                      preserve={false}
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.roster?.type !== currentValues.roster?.type
                      }
                      name={['roster', 'id']}
                      dependencies={['roster', 'type']}
                      rules={[
                        {
                          required: true,
                          message: 'Please type a Identity Source Id!',
                        },
                      ]}
                    >
                      <S.IdentitySourceInput
                        type="text"
                        bordered={changeIdentitySourceVisible}
                        disabled={!changeIdentitySourceVisible}
                      />
                    </AntdForm.Item>
                  </Col>

                  {(isAdiSuperAdmin || isAdiAdmin) && (
                    <Col span={changeIdentitySourceVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                      <S.EditButton
                        type="primary"
                        shape="circle"
                        icon={changeIdentitySourceVisible ? <BiCheck /> : <BiEdit />}
                        onClick={handleEditingIdentitySourceButton}
                      />
                    </Col>
                  )}

                  {(isAdiSuperAdmin || isAdiAdmin) && changeIdentitySourceVisible && (
                    <Col span={changeIdentitySourceVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                      <S.CancelButton
                        type="ghost"
                        shape="circle"
                        icon={<MdCancel />}
                        onClick={() => handleCancelEditingButton(setChangeIdentitySourceVisible)}
                      />
                    </Col>
                  )}
                </Row>
              </S.Line>
            ) : null
          }
        </AntdForm.Item>
      </Row>
    ),
    [
      changeInvestigationLibraryLimitVisible,
      changeWritersQuantityVisible,
      changeIdentitySourceVisible,
      customLibrary,
      handleEditingInvestigationLibraryLimitButton,
      handleEditingWritersButton,
      handleEditingIdentitySourceButton,
      handleCancelEditingButton,
      isAdiSuperAdmin,
      isAdiAdmin,
      isTrial,
      changeStateStandardsVisible,
      handleEditingStateStandardsButton,
      statesData,
    ],
  );

  const checkArea = useMemo(
    () =>
      !isOrganizationAdiAdmin
        ? null
        : customLibrary && (
            <S.Line md={24} lg={6}>
              <Row>
                <Col span={24}>
                  <Popconfirm
                    placement="topLeft"
                    title={`Are you sure you want to ${!blockAdiInvestigations ? 'disable' : 'enable'} ADI library?`}
                    onConfirm={handleOkBlockLibrary}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Switch
                      type="grid"
                      title={<S.TitleInput $padding="0 5px">Teachers can choose from ADI Library</S.TitleInput>}
                      checked={!blockAdiInvestigations}
                      value={!blockAdiInvestigations}
                      titleAlign="left"
                    />
                  </Popconfirm>
                </Col>
              </Row>
            </S.Line>
          ),
    [blockAdiInvestigations, handleOkBlockLibrary, isOrganizationAdiAdmin, customLibrary],
  );

  const settingsTab = useMemo(
    () => (
      <S.Body span={24}>
        {isTrial && <S.Ribbon>Trial Account</S.Ribbon>}

        <Form form={form}>
          <Row gutter={24} style={{ padding: isOrganizationAdiAdmin ? '24px' : '24px 24px 0 24px ' }}>
            <S.Line md={24} lg={6}>
              <S.TitleInput>Organization Name</S.TitleInput>
              <Row>
                <Col span={changeNameVisible ? 16 : 20}>
                  <AntdForm.Item name="name">
                    <S.SInput disabled={!changeNameVisible} bordered={changeNameVisible} />
                  </AntdForm.Item>
                </Col>

                {(isAdiSuperAdmin || isAdiAdmin) && (
                  <Col span={changeNameVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                    <S.EditButton
                      type="primary"
                      shape="circle"
                      icon={changeNameVisible ? <BiCheck /> : <BiEdit />}
                      onClick={() => handleEditingButton(changeNameVisible, setChangeNameVisible)}
                    />
                  </Col>
                )}

                {(isAdiSuperAdmin || isAdiAdmin) && changeNameVisible && (
                  <Col span={changeNameVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                    <S.CancelButton
                      type="ghost"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => handleCancelEditingButton(setChangeNameVisible)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>

            <S.Line md={24} lg={6}>
              <S.TitleInput>Subscription Duration</S.TitleInput>
              <Row>
                <Col span={changeSubscriptionVisible ? 16 : 20}>
                  <AntdForm.Item
                    name="subscriptionData"
                    rules={[
                      {
                        required: true,
                        message: 'Please select the subscription duration!',
                      },
                    ]}
                  >
                    {changeSubscriptionVisible ? (
                      <RangePicker
                        defaultValue={subscriptionDuration}
                        onChange={setSubscriptionDuration}
                        allowClear={false}
                      />
                    ) : (
                      <S.SubText>
                        {subscriptionDuration?.startDate || formatDateTime(organization?.subscription?.startDate)} -{' '}
                        {subscriptionDuration?.endDate || formatDateTime(organization?.subscription?.endDate)}
                      </S.SubText>
                    )}
                  </AntdForm.Item>
                </Col>

                {(isAdiSuperAdmin || isAdiAdmin) && (
                  <Col span={changeSubscriptionVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                    <S.EditButton
                      type="primary"
                      shape="circle"
                      icon={changeSubscriptionVisible ? <BiCheck /> : <BiEdit />}
                      onClick={handleEditingDatesButton}
                    />
                  </Col>
                )}

                {(isAdiSuperAdmin || isAdiAdmin) && changeSubscriptionVisible && (
                  <Col span={changeSubscriptionVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                    <S.CancelButton
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={handleCancelEditingDatesButton}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>

            <S.Line md={24} lg={6}>
              <S.TitleInput>Max. Licenses</S.TitleInput>
              <Row>
                <Col span={changeLicensesVisible ? 16 : 20}>
                  <AntdForm.Item
                    name="invitesAvailable"
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: 'Please add licenses!',
                      },
                    ]}
                  >
                    <S.SInput
                      type="number"
                      min={1}
                      bordered={changeLicensesVisible}
                      disabled={!changeLicensesVisible}
                    />
                  </AntdForm.Item>
                </Col>

                {(isAdiSuperAdmin || isAdiAdmin) && (
                  <Col span={changeLicensesVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                    <S.EditButton
                      type="primary"
                      shape="circle"
                      icon={changeLicensesVisible ? <BiCheck /> : <BiEdit />}
                      onClick={handleEditingLicensesButton}
                    />
                  </Col>
                )}

                {(isAdiSuperAdmin || isAdiAdmin) && changeLicensesVisible && (
                  <Col span={changeLicensesVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                    <S.CancelButton
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => handleCancelEditingButton(setChangeLicensesVisible)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>

            <S.Line md={24} lg={isTrial ? 5 : 6}>
              <S.TitleInput>TAs per Teacher</S.TitleInput>
              <Row>
                <Col span={changeTAQuantityVisible ? 16 : 20}>
                  <AntdForm.Item
                    name="defaultTAQuantity"
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: 'Please add Teacher Assistants quantity!',
                      },
                    ]}
                  >
                    <S.SInput
                      type="number"
                      min={1}
                      bordered={changeTAQuantityVisible}
                      disabled={!changeTAQuantityVisible}
                    />
                  </AntdForm.Item>
                </Col>

                {(isAdiSuperAdmin || isAdiAdmin) && (
                  <Col span={changeTAQuantityVisible ? 4 : 4} style={{ textAlign: 'right' }}>
                    <S.EditButton
                      type="primary"
                      shape="circle"
                      icon={changeTAQuantityVisible ? <BiCheck /> : <BiEdit />}
                      onClick={handleEditingTAQuantityButton}
                    />
                  </Col>
                )}

                {(isAdiSuperAdmin || isAdiAdmin) && changeTAQuantityVisible && (
                  <Col span={changeTAQuantityVisible ? 4 : 0} style={{ textAlign: 'right' }}>
                    <S.CancelButton
                      type="primary"
                      shape="circle"
                      icon={<MdCancel />}
                      onClick={() => handleCancelEditingButton(setChangeTAQuantityVisible)}
                    />
                  </Col>
                )}
              </Row>
            </S.Line>
            {checkArea}
          </Row>

          {(isAdiSuperAdmin || isAdiAdmin) && customLibraryControls}
        </Form>
      </S.Body>
    ),
    [
      isTrial,
      form,
      isOrganizationAdiAdmin,
      changeNameVisible,
      isAdiSuperAdmin,
      isAdiAdmin,
      changeSubscriptionVisible,
      subscriptionDuration,
      organization,
      handleEditingDatesButton,
      handleCancelEditingDatesButton,
      changeLicensesVisible,
      handleEditingLicensesButton,
      changeTAQuantityVisible,
      handleEditingTAQuantityButton,
      checkArea,
      customLibraryControls,
      handleEditingButton,
      handleCancelEditingButton,
    ],
  );

  return (
    <Row>
      <Col span={24}>
        {isAdiSuperAdmin || isAdiAdmin ? (
          <Tabs
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            onChange={(activeKey: string) => setActiveTab(activeKey)}
          >
            <TabPane tab={<S.TitleTab>Settings</S.TitleTab>} key="settings">
              {settingsTab}
            </TabPane>
            <TabPane tab={<S.TitleTab>Permissions</S.TitleTab>} key="subscription">
              <S.Body span={24}>
                <S.Line span={24}>
                  <Row gutter={[0, 16]} style={{ padding: 24 }}>
                    <Col span={8}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${
                          isTrial ? 'disable' : 'enable'
                        } trial subscription permissions?`}
                        onConfirm={handleOkIsTrial}
                        disabled={!isAdiSuperAdmin && !isAdiAdmin}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Switch
                          type="text"
                          title={
                            <span role="none" >
                              Trial Subscription{' '}
                              <Tooltip title="Limits access to resources.">
                                <FiHelpCircle />
                              </Tooltip>
                            </span>
                          }
                          checked={isTrial}
                          value={isTrial}
                          titleColumns={17}
                          titleAlign="right"
                        />
                      </Popconfirm>
                    </Col>
                    <Col span={8}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${
                          customTAPermisions ? 'disable' : 'enable'
                        } super TA permissions?`}
                        onConfirm={() =>
                          submitToggleAssistantCustomPermission({
                            variables: {
                              data: {
                                subscriptionId: organization?.subscription.id,
                                taCustomPermissions: !customTAPermisions,
                              },
                            },
                          })
                        }
                        okText="Yes"
                        disabled={!isAdiSuperAdmin && !isAdiAdmin}
                        cancelText="No"
                      >
                        <Switch
                          checked={customTAPermisions}
                          value={customTAPermisions}
                          disabled={!isAdiSuperAdmin && !isAdiAdmin}
                          type="text"
                          titleAlign="right"
                          titleColumns={17}
                          title={
                            <span role="none" >
                              Super TA{' '}
                              <Tooltip title="Allows teacher assistants to grade final reports and message with students.">
                                <FiHelpCircle />
                              </Tooltip>
                            </span>
                          }
                        />
                      </Popconfirm>
                    </Col>
                    <Col span={8}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${
                          readerPermissions ? 'disable' : 'enable'
                        } immersive reader permissions?`}
                        onConfirm={handleOkReaderPermissions}
                        okText="Yes"
                        disabled={!isAdiSuperAdmin && !isAdiAdmin}
                        cancelText="No"
                      >
                        <Switch
                          type="text"
                          title={
                            <span role="none" >
                              Immersive Reader{' '}
                              <Tooltip title="Allows students to use the immersive reader on investigations.">
                                <FiHelpCircle />
                              </Tooltip>
                            </span>
                          }
                          checked={readerPermissions}
                          value={readerPermissions}
                          titleColumns={17}
                          titleAlign="right"
                        />
                      </Popconfirm>
                    </Col>
                    <Col span={8}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${
                          teachersCanDoReviews ? 'disable' : 'enable'
                        } super teacher permissions?`}
                        onConfirm={handleOkTeacherReviews}
                        okText="Yes"
                        disabled={!isAdiSuperAdmin && !isAdiAdmin}
                        cancelText="No"
                      >
                        <Switch
                          type="text"
                          title={
                            <span role="none" >
                              Super Teacher{' '}
                              <Tooltip title="Allows teachers and teacher assistants to complete peer reviews and reflections for students.">
                                <FiHelpCircle />
                              </Tooltip>
                            </span>
                          }
                          checked={teachersCanDoReviews}
                          value={teachersCanDoReviews}
                          titleColumns={17}
                          titleAlign="right"
                        />
                      </Popconfirm>
                    </Col>
                    <Col span={8}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${
                          customLibrary ? 'disable' : 'enable'
                        } custom investigation permissions?`}
                        onConfirm={handleOkCustomLibrary}
                        okText="Yes"
                        disabled={!isAdiSuperAdmin && !isAdiAdmin}
                        cancelText="No"
                      >
                        <Switch
                          type="text"
                          title={
                            <span role="none" >
                              Custom Investigations{' '}
                              <Tooltip title="Allows organization administrators to create custom investigations that are only available to the organization.">
                                <FiHelpCircle />
                              </Tooltip>
                            </span>
                          }
                          checked={customLibrary}
                          value={customLibrary}
                          titleColumns={17}
                          titleAlign="right"
                        />
                      </Popconfirm>
                    </Col>
                    <Col span={8}>
                      <Popconfirm
                        placement="topLeft"
                        title={`Are you sure you want to ${
                          reportPermission ? 'disable' : 'enable'
                        } reports and insights permissions?`}
                        onConfirm={handleOkReportPermission}
                        okText="Yes"
                        disabled={!isAdiSuperAdmin && !isAdiAdmin}
                        cancelText="No"
                      >
                        <Switch
                          checked={reportPermission}
                          value={reportPermission}
                          disabled={!isAdiSuperAdmin && !isAdiAdmin}
                          type="text"
                          titleAlign="right"
                          title={
                            <span role="none" >
                              Reports & Insights{' '}
                              <Tooltip title="Allows organization administrators to view Report module and allows teachers to view Insights module.">
                                <FiHelpCircle />
                              </Tooltip>
                            </span>
                          }
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={{ padding: 24 }}>
                    <Divider />
                    <Col span={12}>
                      <Row gutter={[0, 8]} align="middle">
                        <S.TitleInput style={{ margin: 0 }}>Subscription Subjects</S.TitleInput>
                        <Tooltip title="User under this subscription can only assign investigations of subjects set below">
                          <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                        </Tooltip>

                        <AntdForm.Item name="subjects" noStyle>
                          {subjectsList.map((subject, index) => (
                            <Col span={24} key={subject.label}>
                              {subject.key === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION && (
                                <Divider style={{ margin: '35px 0 12px 0' }} />
                              )}
                              <Popconfirm
                                placement="topLeft"
                                title="Confirm changing subject configuration?"
                                onConfirm={() => handleSubjectsChange(subject.key)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Row align="middle">
                                  <Switch
                                    key={subject.label}
                                    loading={loadingSubjectsSubmission}
                                    checked={
                                      !!organization?.subscription?.allowedSubjects?.includes(subject.key) &&
                                      subject.enabled
                                    }
                                    value={
                                      !!organization?.subscription?.allowedSubjects?.includes(subject.key) &&
                                      subject.enabled
                                    }
                                    type="text"
                                    titleAlign="right"
                                    title={subject.label}
                                    disabled={!subject.allowed}
                                  />

                                  {!subject.allowed && (
                                    <Tooltip title="Upgrade your subscription to enable this assessment">
                                      <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                                    </Tooltip>
                                  )}
                                </Row>
                              </Popconfirm>
                            </Col>
                          ))}
                        </AntdForm.Item>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={[0, 8]} style={{ paddingBottom: 8 }} align="middle">
                        <S.TitleInput style={{ margin: 0 }}>Subscription Grade Levels</S.TitleInput>
                        <Tooltip title="User under this subscription can only assign investigations of grades set below">
                          <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
                        </Tooltip>

                        <AntdForm.Item name="gradeBands" noStyle>
                          {gradeBands.map((gradeBand) => (
                            <Col span={24} key={gradeBand}>
                              <Popconfirm
                                placement="topLeft"
                                title="Confirm changing grade band configuration?"
                                onConfirm={() => handleGradesChange(gradeBand)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Switch
                                  loading={loadingGradeBandSubmission}
                                  key={gradeBand}
                                  checked={!!organization?.subscription?.allowedGradeBands?.includes(gradeBand)}
                                  value={!!organization?.subscription?.allowedGradeBands?.includes(gradeBand)}
                                  type="text"
                                  titleAlign="right"
                                  title={`Grade ${gradeBand}`}
                                />
                              </Popconfirm>
                            </Col>
                          ))}
                        </AntdForm.Item>
                      </Row>
                      {texasEditionEnabled && (
                        <>
                          <Divider style={{ margin: '4px 0 12px 0' }} />
                          <Row gutter={[0, 8]} align="middle">
                            {grades.map((gradeBand) => (
                              <Col span={24} key={gradeBand}>
                                <Popconfirm
                                  placement="topLeft"
                                  title="Confirm changing grade band configuration?"
                                  onConfirm={() => handleGradesChange(gradeBand)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Switch
                                    disabled={disableCustomGradeBands && texasEditionEnabled}
                                    loading={loadingGradeBandSubmission}
                                    key={gradeBand}
                                    checked={!!organization?.subscription?.allowedGradeBands?.includes(gradeBand)}
                                    value={!!organization?.subscription?.allowedGradeBands?.includes(gradeBand)}
                                    type="text"
                                    titleAlign="right"
                                    title={`Grade ${gradeBand.replace('tx.', '')}`}
                                  />
                                </Popconfirm>
                              </Col>
                            ))}
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </S.Line>
              </S.Body>
            </TabPane>
          </Tabs>
        ) : (
          settingsTab
        )}
      </Col>
    </Row>
  );
};

export default OrganizationInfo;
